/*! ===== BECOME A PARTNER */
.webform-client-form {
	@include clearfix();
	margin: 2em 0;
	.webform-component-textfield,
	.webform-component-email,
	.webform-component-select {
		display: inline-block;
		width: 32.9%;//3%;
		padding: 0 1em 0 0;
		margin: 0 0 1em 0;
		vertical-align: top;
		position: relative;
		&.wide {
			width: 66.4%;
			@media #{$mobile}{
				width: auto;
			}

		}
		input {
			max-width: 100%;
			width: 100%;
		}
		@media #{$mobile}{
			padding: 0;
			display: block;
			width: auto;
		}
		span.required {
			color: $lime_green;
			position: absolute;
			top: .25em;
			right: 1.25em;
		}
	}
	.webform-component-textarea {
		margin: 0 1em 1em 0;
	}
	@media #{$mobile}{
		.webform-component-select {
			margin-bottom: 1.5em;
		}
		.webform-component-textfield,
		.webform-component-email {
			margin-bottom: 2px;
		}
		.webform-component-textarea {
			margin-top: 0;
		}
	}
}


.webform-component-file {
	position: relative;
	&:before {
		content: 'Upload Logo';
		padding: .8em 1.5em;
		border:1px solid $lightest_grey;
		background: #fff url(../images/download.svg) right 17px center no-repeat;
		padding-right: 40px;
		cursor: pointer;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: $bold;
		letter-spacing: 0.08em;
		display: block;
		position: absolute;
	}
	&:after {
		content: attr(data-filename);
		position: absolute;
		top: 1em;
		left: 14em;
		font-size: 12px;
		color: $light_grey;
		z-index: -1;
	}
}
.webform-component-file .form-managed-file,
.webform-component-file .description,
.webform-component-file label {
	opacity: 0;
}


body.section-about,
body.section-core-partners {
	.region-content-bottom {
		background: none;
		padding-bottom: 80px;
		.block.block-fieldblock {
			padding: 0;
		}
	}
	.page__field_page_bottom, blockquote.logos {
		padding: 0;
		h2 {
			max-width: 880px;
			margin: 0 auto;
			padding: 0 40px 20px;
		}
		p {
			display: inline-block;
			width: 25%;
			@media #{$tablet} {
				width: 33.3333%;
			}
			@media #{$mobile} {
				width: 50%;
			}
			padding: 30px;
			//border: 1px solid #fff;
			text-align: center;
			float: left;
			margin: 0;
			min-height: 122px;
			background: $background;
			position: relative;
			&:before {
				content:'';
				display: block;
				position: absolute;
				bottom:0;
				left: 0;
				width:100%;
				border-top:2px solid #fff;
			}
			&:after {
				content:'';
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				border-right: 2px solid #fff;
			}
		}
		img {
			max-height: 60px;
			width: auto;
			vertical-align: middle;
		}
	}
	blockquote.logos {
		float:left;
		margin-left:0;
		margin-right:0;
	}
}


sup.note {
	color: $lime_green;
	font-size: 1.5em;
	cursor: pointer;
	top: 0;
	position: relative;
	span {
		display: none;
	}
	&:hover,
	&.active {
		span {
			position: absolute;
			display: block;
			top: 1.5em;
			left:-10em;
			min-width: 20em;
			padding: 16px;
			background: #fff;
			border:1px solid $lightest_grey;
			font-size: .8rem;
			color: $body;
			line-height: 1.3;
			border-radius: 4px;
			box-shadow: 0 0 5px rgba(100,100,100,.4);
			&:before {
				content:'';
				display: block;
				position: absolute;
				left:9.95em;
				top:-6px;
				height: 10px;
				width: 10px;
				border-top:1px solid $lightest_grey;
				border-right: 1px solid $lightest_grey;
				transform: rotate(-45deg);
				background: #fff;
			}
		}
	}
	&.right:hover span {
		left: auto;
		right:-1em;
		&:before {
			left:auto;
			right: 1em;
		}
	}
}


/*! ====== MEDIA */

.views-widget-filter-field_media_room_tid .form-item {
	display: inline-block;
	& + .form-item:before {
		content: ' / ';
	}
}
.view-project-reports,
.view-media-room {
	//@include maxwidth(800px,40px,20px);
	h2.block__title {
		margin: 0 0 1em 0;
	}
	.views-row + h2.block__title {
		margin: 3em 0 1em;
	}
	&.view-display-id-page .views-row {
		padding-right: 100px;
	}
	.views-row {
		position: relative;
		padding-bottom: 1em;
		padding-top: 1em;
		//border-bottom: 1px solid $lightest_grey;
		& + .views-row {
			border-top: 1px solid $lightest_grey;
		}
		//font-weight: $bold;
		.views-field-body,.views-field-created {
		//	font-weight: $normal;
		}
		p a {
			color: $body;
			&:hover {
				color: $lime_green;
			}
		}
		h2 {
			margin: 0;
		}
		.with-download h2 {
			margin-right: 70px;
		}
		.submitted + h2 {
			margin-top: .5em;
		}

		.svg {
			position: absolute;
			right: 0;
			top: 20px;
			padding: 0 20px 0 0;
			-webkit-transition: padding .25s ease-in-out;
			-moz-transition: padding .25s ease-in-out;
			-ms-transition: padding .25s ease-in-out;
			transition: padding .25s ease-in-out;
			&:hover {
				padding: 0 0 0 20px;
			}
			&.download-link {
				svg {
					width: 22px;
					height: 35px;
					g {
						-webkit-transition: all .15s ease-in-out;
						transform: all .15s ease-in-out;
						-webkit-transform: translateY(-4px);
						-moz-transform: translateY(-4px);
						-ms-transform: translateY(-4px);
						transform: translateY(-4px);
					}
				}
				//top: 0;
				padding: 20px 8px 20px 0;
				&:hover {
					svg g {
						-webkit-transform: translateY(0);
						-moz-transform: translateY(0);
						-ms-transform: translateY(0);
						transform: translateY(0);
					}
					//padding: 0 20px 40px 0;
				}

			}
		}
		svg line,
		svg polyline,
		svg path {
			stroke: $lime_green;
		}

		&:hover svg line,
		&:hover svg polyline,
		&:hover svg path {
			stroke: $light_grey;
		}
	}
	.views-field-created {
		margin: 1em 0 0 0;
	}
}




/*! ====== FUNDERS */
body.section-funders {
	.region-content-bottom .block {
		padding: 60px 40px;
		max-width: 1024px;

	}
}

.view-funders {
	dd img {
		max-height: 70px;
		max-width: 200px;
		height: auto;
		width: auto;
	}
	dd .views-field + * {
		margin-top: 1.5em;
	}

	/*
	.td {
		@media #{$narrow} {
			width: 25%;// 33.3333%;
		}
		padding: 20px;
	}
	img {
		max-width: 100%;
		height: auto;
		cursor: pointer;
		max-height: 60px;
		width: auto;
		@media #{$mobile} {
			max-height: 60px;
			width: auto;
			max-width: 200px;
		}
	}
	.views-field-field-image + .views-field {
		display: none;
	}
	.fieldset {
		max-height: 0;
		overflow: hidden;
		opacity: 0;
		background: #fafafa;
		padding: 40px;
		box-sizing: border-box;
		position: fixed;
		z-index: 100;
		top: 80%;
		left: 50%;
		width: 90%;
		max-width: 500px;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		-ms-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		&:before {
			content:'x';
			position: absolute;
			top:10px;
			right: 10px;
		}
		h2 {
			margin: 0 0 .5em;
		}
		h4 {
			margin: 1em 0 0;
		}
		a.button {
			margin-top: 1.5em;
			text-transform: none;
			border: none;
		}
	}
	.td.active .fieldset {
		//display: block;
		opacity: 1;
		max-height: 800px;
		top: 50%;
	}
	.td.active:before {
			content:'';
			display: block;
			position: fixed;
			top:0;
			left: 0;
			bottom: 0;
			width: 100%;
			background: rgba(255,255,255,.8);
	}
	*/
}
/*
ul.htabs {
	list-style: none;
	font-size: .8rem;
	text-transform: uppercase;
	margin: 40px 0;
	padding: 0;
	li {
		display: inline-block;
		margin-right: 20px;
		a {
			padding: 10px;
			color: $light_grey;
			&.active {
				color: $lime_green;
				background: #EFEEEB;
			}
		}
	}
}*/
.hidden {
	display: none;
	&.active {
		display: block;
	}
}

body.page-splashify-splash {
	main {
		padding: 0 40px 40px;
		.hidden,
		.get-started {
			display: none;
			&.active {
				display: block;
			}
		}
		h2 {
			margin: 2em 0 .5em;
		}

	}
}



/*! ====== BLOG STUFF */
.field_image {
	margin-bottom: 2em;
	img {
		display: block;
	}
}


/*! ====== RESOURCES */
.view-featured-resources {
  border-top: 3px solid $lightest_grey;
  .views-row {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .views-row + .views-row {
    border-top: 1px solid $lightest_grey;
  }
}
.node-resource {
  header {
    display: table;
    width: 100%;
    a.icon-large,
    .meta,
    a.icon-small {
      display: table-cell;
    }
    .meta {
      padding: 0 30px 0 0;
    }
    a.icon-large + .meta {
      padding-left: 30px;
    }
  }
  & + .node-resource {
  }

  h2 {
    margin: .5em 0;
    font-weight: $bold;
    font-size: 1rem;
    a {
      color: $body;
      &:hover {
        color: $link;
      }
    }
  }
  header p,
  header p.submitted {
    //color: $light_grey;
    margin: 0;
    font-size: 12px;
  }


  a.icon-large {
    width: 51px;
    height: 51px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  a.icon-small {
    width: 30px;
    height: 30px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  &.report {
    a.icon-large {
      background-image: url(../images/resource-icon-file.png);
    }
    a.icon-small {
      background-image: url(../images/download-grey.svg);
    }
  }
  &.link {
    a.icon-large {
      background-image: url(../images/resource-icon-link.png);
    }
    a.icon-small {
      background-image: url(../images/view-project.svg);
    }
  }
  &.video {
    a.icon-large {
      background-image: url(../images/resource-icon-video.png);
    }
    a.icon-small {
      background-image: url(../images/play-grey.svg);
    }
  }

  .field_video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    opacity: 0;
    background: rgba(255,255,255,.8);
    transition: height .5s .5s, opacity .5s ease-in-out;
    z-index: 1000;
    .embedded-video {
      max-width: 640px;
      height: 100vh;
      margin: auto;
      position: relative;
      .player {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        height: 0;
        padding-bottom: 56.75%;
      }
    }
    button.close {
      position: absolute;
      right: 0;
      top: -30px;
      padding: 0;
      background: none;
      color: $body;
    }
  }
  &.active .field_video {
    opacity: 1;
    height: 100%;
    transition: height .5s 0, opacity .5s ease-in-out;
  }
}
