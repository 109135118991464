.view-projects,
.view-id-research {
	.td {
		position: relative;
		width: 33.3333%;
		@media #{$mobile}{
			width: 100%;
		}
		& + .td {
			border-left: 1px solid;
			@media #{$mobile}{
				border-left: none;
			}
		}
	}
	img {
		display: block;
	}
	.views-field-title {
		position: absolute;
		bottom: 20px;
		left: 20px;
		right: 20px;
		padding: 20px;
		background: #fff;
		font-size: 1em;
		line-height: 1.3;
	}
}


.title-summary a.focus-area {
	font-size: 80%;
}

body.node-type-project {
	.title-summary.fixed {
		position: fixed;
		width: 100%;
		top: -100px;
		z-index: 99;
		h1 {
			font-size: 1em;
		}
	}
	main {
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
	//header[role="banner"],
	//h1.page__title,
	.header-left-right,
	.horizontal-tabs-panes,
	.region-footer {
		@include maxwidth(800px,40px,20px);
	}
	.horizontal-tabs-panes {
		padding-top: 40px;
		padding-bottom: 40px;
		.horizontal-tab-button.first.last {
			display: none;
		}
	}
	.horizontal-tabs-panes .horizontal-tabs-panes {
		padding-left: 0;
		padding-right: 0;
		padding-top: 10px;
	}
	.messages,
	.tabs {
		max-width: 1120px;
		margin: 2em auto;
	}
}

.node-partner,
.node-project {
	.header-left-right {
		@include clearfix();
		//max-width: 800px;
		//margin: 0 auto;
		padding-bottom: 40px;
	}
	.group-header {
		margin: 0 0 40px;
		img {
			max-width: 200px;
			max-height: 200px;
			display: block;
			margin: 0 auto;
			}
	}
	.node-project-header {
		.post_date {
			margin: 0 0 1em;
			font-size: 80%;
		}
	}
	.group-left,
	.node-project-left {
		width: 66.6666%;
		float: left;
		padding-right: 3em;
	}
	.group-right,
	.node-project-right {
		width:33.3333%;
		float: right;
		//border-left: 1px solid $lightest_grey;
		padding-left: 1.5em;
		background: $background;
		padding: 1.5em;
		border-radius: 5px;
		.flag-outer,
		.field {
			//padding-left: 1.5em;
			@media #{$mobile} {
				padding-left: 0;
			}
			.flag {
				display: block;
				padding-left: 0;
				padding-right: 0;
				text-align: center;
			}
			.flag-throbber {
				display: none;
			}
			& + * {
				margin: 2em 0 0;
			}
		}
		a.button {
  		display: block;
		}
		.field + .field {
			//margin-top: 1em;//.25em;
		}
		font-size: 80%;
		.field_focus_area {
			//display: none;
		}
	}
	.field.project__field_reports {
		padding-bottom: 2em;
		margin-top: 0;
		&.field {
			padding-left: 0;
		}
		.download {
			display: block;
		}
		dt {
			padding: 0 1.5em;
			font-weight: $bold;
		}
		dt span {
			color: $lime_green;
			font-size: 1.75em;
			float: right;
			font-weight: $light;
			line-height: .6;
		}
		dd {
			margin: 0 0 0 1.5em;
			padding: 1em 0 0;
		}
		dd .field-item {// + .field-item {
			margin-top: 20px;
		}
		dt.active + dd {
			padding: 1em 0 0;
		}
	}
	.group-team {
		//border-top: 1px solid $lightest_grey;
		//padding-top: 1em;
		padding-bottom: 1em;
		//padding-left: 1.5em;
		border-bottom: 1px solid $lightest_grey;
		.field {
			padding-left: 0;
		}
		& + .flag-outer,
		& + .field {
			margin-top: 2.5em;
		}
	}
	.field_team {
		border-bottom: 1px solid $lightest_grey;
		padding-bottom: 1em;
		margin-bottom: 2.5em !important;
		//margin-top: 2em !important;
		.field-label {
			font-size: 150%;
			margin: 1em 0 .5em;
			font-weight: $light;
		}
		p {
			margin: 0;
		}
	}
	.share {
		padding-top: 2.5em;
		padding-bottom: 2.5em;
		margin-top: 2em !important;
		//text-align: center;
		.field-label {
			display: inline;
		}
	}
	.project__subscribe {
		margin: 2.5em 0;
	}
	.field_team_members,
	.field_team_member {
		@include clearfix();
		.field-item {
			width: 100px;//50%;
			min-height: 145px;
			float: left;
			@media #{$narrow} {
			&.even {
				clear:left;
			}
			}
			figure {
				margin: 0 1em 1em 0;
				line-height: 1.2;
				figcaption {
					padding: .5em 0;
				}
			}
			@media #{$mobile} {
				//width: 100%;
				//float: none;
			}
		}
		& + * {
			clear: both;
		}
	}

	.field_author {
		//padding-top: 2em;
	}

	.group-primary {
		.fieldset-wrapper > .field-type-text-long {
			margin: 2em 0;
		}
		.comment .field-type-text-long,
		.comment-form .field-type-text-long {
			margin: 0;
		}
	}
	.group-footer {
		clear: both;
		background: $background;
		margin: 1em 0 0;
		input[type="text"],
		input[type="password"] {
			border: 1px solid $lightest_grey;
		}
	}
	@media #{$mobile} {
		.group-left,
		.group-right {
			float: none;
			width: auto;
		}
	}
}

label.more + div.more {
	display: none;
}

div.horizontal-tabs {
	border: none;
	margin: 0;
	.horizontal-tab-hidden {
		display: none;
	}
	ul.horizontal-tabs-list {
		background: none;
		border-right: none;
		text-transform: uppercase;
		font-size: 80%;
		li {
			background: none;
			border: none;
			padding: 12px 0;
			margin-right: 30px;//64px;
			min-width: 3em;
			a {
				display: inline-block;
				padding: 12px 20px;
				background: none;
				border-radius: 5px;
			}
			&.selected {
				padding: 12px 0;
				background: none;
				a {
					padding: 12px 20px;
					background: #EFEEEB;
					color: $lime_green;
				}
			}
			&:hover,
			a:hover {
				background: none;
				border: none;
				a {
					background: #EFEEEB;
				}
			}
			&.selected strong,
			strong {
				font-weight: $normal;
				color: inherit;
			}
		}
	}

	.horizontal-tabs-list-secondary {
		li.first.last {
			display: none;
		}
	}

	// Top level tabs, slightly different:
	.horizontal-tabs-list-wrapper {
		border-top: 1px solid $lightest_grey;
		border-bottom: 1px solid $lightest_grey;
		margin: 0;
		background: #fff;

		&.fixed {
			position: fixed;
			width: 100%;
			z-index: 101;
			ul.horizontal-tabs-list li.follow {
				opacity: 1;
				-webkit-transform: translateY(0);
				-moz-transform: translateY(0);
				-ms-transform: translateY(0);
				transform: translateY(0);
			}
		}
		ul.horizontal-tabs-list {
			@include clearfix();
			display: block;
			//max-width: 800px;
			//margin:0 auto;
			//padding: 0 40px;
			@include maxwidth(800px,40px,20px);
			font-size: 125%;
			text-transform: none;
			font-weight: $normal;
			li {
				padding: 15px 0;
				margin-right: 30px;
				strong,
				&.selected strong {
					line-height: 40px;
				}
				a {
					padding: 0;
				}
				&.selected a {
					padding: 0;
					background: none;
				}
				&:hover a {
					background: none;
				}

				&.follow {
					margin-right: 0;
					opacity: 0;
					-webkit-transform: translateY(-200%);
					-moz-transform: translateY(-200%);
					-ms-transform: translateY(-200%);
					transform: translateY(-200%);
					-webkit-transition: all .25s;
					transition: all .25s;
					/*padding-left: 40px;
					padding-right: 40px;
					margin-right: 0;
					background: $teal;
					width: 31%;
					text-align: center;*/
					float: right;
					/*a {
						padding: 0;
						color: #fff;
						background: none;
					}*/
					a.button {
						@include button('blue');
						margin-top: 3px;
					}
				}
			}
		}
	}

	fieldset.horizontal-tabs-pane {
		padding: 0;
		left: 0;
		margin: 0;
	}
}

article.node-discussion {
	padding: 20px;
	background: #EFEEEB;
	& + .comments {
		margin: 0 20px;
	}
}
// 2nd + Guided discussion:
section.comments + article.node-discussion {
	border-top: 1px solid $light_grey;
}

.community_comments {
	h2.block__title {
		margin: 2em 0 1em;
	}
	.comment-form {
		.form-item {
			margin: 0;
		}
	}
}



/*! ====== RESEARCH PAGE */
@media #{$narrow} {
	body.section-funders .page__summary,
	body.page-research .page__summary {
		display: table;
		p {
			display: table-cell;
			width: 66%;
			vertical-align: top;
			& + p {
				width: 34%;
				text-align: right;
			}
		}
	}
}


dl.research__focus-area,
section[class*="views__research-block"] {
	border-left: 10px solid;
	//padding-left: 60px;
	background: #fff;
	position: relative;

	dt {
		padding-left: 60px;
		padding: 20px 70px 20px 20px;
		@media #{$tablet_up} {
			padding: 40px 70px 40px 60px;
		}
		position: relative;
		&:before {
			content:'';
			display: block;
			position: absolute;
			top:0;
			right:0;
			width:100%;
			height:100%;
			background: #fff;//rgba(255,255,255,.9);
			z-index: 0;
			-webkit-transition: width .25s ease-in-out;
			-moz-transition: width .25s ease-in-out;
			-ms-transition: width .25s ease-in-out;
			transition: width .25s ease-in-out;
		}
		&:hover:before {
			width:0%;
		}
		h2 {
			margin: 0;
			color: $mid_grey;
			letter-spacing: .06em;
			z-index: 2;
			position: relative;
			span.title {
				font-weight: 200;
			}

			span.icon {
				position: absolute;
				@media #{$narrow} {
					left: -50px;
				}
			}
			span.counter {
				display: block;
				float: right;
				font-size: .8rem;
				text-transform: uppercase;
				text-align: center;
				font-weight: $normal;
				letter-spacing: 0;
				position: relative;
				top: -.75em;
				em {
					font-style: normal;
					display: block;
					font-weight: $bold;
					font-size: 1.5rem;
				}
			}
			@media #{$mobile} {
				&:after {
					content:'';
					display: block;
					clear: both;
				}
				span.icon {
					width: 30px;
				}

				span.title {
					//float: left;
					//width: 70%;
					padding-left: 40px;
					display: inline-block;
				}
				span.counter {
					//top: 0;
					//width: 30%;
					display: none;
				}
			}
		}
		@media #{$mobile} {
			h2 {
				font-size: 1.2em;
				line-height: 1.3;
			}
		}
		&.active + dd {
			padding-top: 1.5em;
		}
	}
	h2.block__title {
		display: none;
	}
	svg.svg-plus,
	svg.svg-close {
		position: absolute;
		top: 44px;
		right: 20px;
		//-webkit-transition: -webkit-transform .2s;
		//-moz-transition: -moz-transform .2s;
		//-ms-transition: -ms-transform .2s;
		//transition: transform .2s;

		@media #{$mobile} {
			width: 30px;
			top: 50%;
			margin-top:-15px;
		}
	}
	svg.svg-close {
		display: none;
	}
	.active {
		svg.svg-plus {
			//display: none;
			//-webkit-transform: rotate(135deg);
			//-moz-transform: rotate(135deg);
			//-ms-transform: rotate(135deg);
			//transform: rotate(135deg);
			//line {
			//	width: 0;
			//}
		}
		svg.svg-close {
			//display: block;
		}
	}

	p {
		@media #{$narrow} {
			max-width: 85%;
		}
	}
	form {
		//max-width: 70%;
		&.has-partners,
		&.has-regions {
			margin: 1em 60px;//auto;
		}
		@media #{$tablet} {
			max-width: 85%;
		}
		@media #{$mobile} {
			max-width: 100%;
			&.has-partners,
			&.has-regions {
				margin: 1em 20px;
			}
		}
	}
	& + dl.research__focus-area,
	& + section[class*="views__research-block"] {
		margin-top: 5px;
	}

	form {
		@include clearfix();
		.form-item {
			margin: 0 2em 0 0;
			@media #{$tablet_up} {
				float: left;
				width: 30%;//3.3333%;
			}
			&.form-item-regions select {
				border-right: none;
				width: 100%;
			}
			&.form-item-partners {
				//width: 66.6666%;
				select,
				.chosen-container {
					width: 100% !important;
				}
			}
			@media #{$mobile} {
				float: none;
				clear: both;
				margin: 0 0 1em 0;

				&.form-item-regions select {
					border-right: 1px solid $lightest_grey;
				}
			}
		}
	}

}

.apec_helpers__apec_partner_projects,
dl.research__focus-area {
	dd {
		margin: 0;
		padding-bottom: 0;
		p {
			padding: 0 60px;
			@media #{$mobile} {
				padding: 0 20px;
			}
			@media #{$narrow} {
				max-width: 85%;
			}
			& + form {

				&.has-partners,
				&.has-regions {
					margin-top: 60px;
				}
			}
		}
	}

	.table {
		border-spacing: 60px;
	}
	dd span.td,
	dd a.td {
		position: relative;
		padding: 20px 20px 80px;
	}
	@media #{$narrow} {
		dd .td,
		dd.count-1 .td {
			width: 100%;
			width: 33.3333%;
		}
		dd.count-2 .td {
			width: 50%;
			width: 33.3333%;
		}
		dd.count-0 .td {
			width: 33.3333%;
		}
	}
	@media #{$tablet} {
		.table,
		.tr,
		dd a.td,
		dd span.td {
			display: block;
			width: auto;
		}
		dd a.td {
			margin: 20px;
		}
		dd span.td {
			margin: 0 20px;
			padding: 0 20px;
		}
	}
	.tr {
		opacity: 0;
		-webkit-transition: opacity .35s .5s;
		-moz-transition: opacity .35s .5s;
		-ms-transition: opacity .35s .5s;
		transition: opacity .35s .5s;
	}
	dt.active + dd .tr {
		opacity: 1;
	}
	dd a.td {
		position: relative;
		top: 60px;
		background: $background;
		-webkit-transition: background .25s, top .35s .5s;
		-moz-transition: background .25s, top .35s .5s;
		-ms-transition: background .25s, top .35s .5s;
		transition: background .25s, top .35s .5s;
		height: 300px;
		@media #{$tablet} {
			height: auto;
		}
		img {
			display: block;
		}
		h2 {
			//position: absolute;
			//bottom: 20px;
			//left: 20px;
			//right: 20px;
			//background: #fff;
			//padding: 20px;
			//font-size: 1em;
			line-height: 1.4;
			//width: 100%;
			color: $teal;
			font-weight: $normal;
			margin: 0;
			font-size: 1.3em;
		}
		span {
			position: absolute;
			bottom: 0;
			left:0;
			width: 100%;
			padding: 15px 20px;
			background: #F5F5F6;
			font-size: 80%;
			text-transform: uppercase;
			color: $light_grey;
			-webkit-transition: background .25s;
			-moz-transition: background .25s;
			-ms-transition: background .25s;
			transition: background .25s;
			line-height: 17px;//28px;
			svg {
				float: right;
			}
		}
		& + a.td {
			//border-left: 1px solid;
		}
		&:hover {
			background: #E0EEEE;
			span {
				background: #83BDBD;
				color: #fff;
			}
			svg path,
			svg line,
			svg polyline {
				stroke: #fff !important;
			}
			svg polygon,
			svg rect {
				fill: #fff !important;
			}
		}
	}
	dt.active + dd a.td {
		top: 0;
	}
}
