* {
  box-sizing: border-box;
}

html {
  line-height: 1.5em;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}


ul.tabs,
ul.htabs {
	margin: 2em 0 0 0;
	padding: 0;
	text-transform: uppercase;
	font-size: 80%;
	line-height: 1.2;
	font-weight: $bold;
	letter-spacing: .06em;
	li {
		display: inline-block;
		padding: .5em 0;
		& + li {
			margin-left: 1.5em;
		}
		@media #{$mobile} {
			display: block;
			margin-left: 0 !important;
			float: none;
			clear: both;
		}
		a {
			display: inline-block;
			padding: 12px 20px;// .5em 1em .35em;
			border-radius: 5px;
			border: 1px solid $lightest_grey;
			&:hover  {
				background: $background;
			}
			&.active {
				background: $background;
				color: $lime_green;//#fff;
				border: 1px solid $background;
			}
		}
	}
}
ul.tabs--secondary,
ul.htabs {
	li {
		padding: .5em 20px;
		.field-name-body & {
			padding: .5em 20px .5em 0;
		}
		a {
			border-radius: 0;
			padding: 0 0 6px;
			border:none;
			&:hover {
				background: none;
			}
			&.active {
				background: none;
			border:none;
				border-bottom: 2px solid $lime_green;
			}
		}
	}
}
ul.htabs {
	margin: 2em 0;
}

.messages {
	border-radius: 2px;
	padding: 10px 10px 10px 40px;
	margin: 20px 0;
	background: #f1fbea;
	border: 1px solid #d4f1bc;
	color: #457a1a;
	&.messages--warning {
		background: #fcf9e0;
		border-color: #f5eb9b;
		color: #6c610b;
	}
	&.messages--error {
		background: #fef4f5;
		border-color: #f8a9aa;
		color: #c60f13;
	}
}

.table {
	display: table;
	width: 100%;
	@media #{$mobile}{
		display: block;
	}
}

.tr {
	display: table-row;
	@media #{$mobile}{
		display: block;
	}
}

.td {
	display: table-cell;
	@media #{$mobile}{
		display: block;
	}
}



#navbar-administration .navbar-bar {
  background: #3e474d;
  background: #59666f;
  color: #e1e4e6;
}

#navbar-administration .navbar-tray .navbar-active-trail > .navbar-box .navbar-menu-item,
#navbar-administration .navbar-tray .navbar-active,
#navbar-administration .navbar-tray .active {
	color: #59666f;
	color: #3e474d;
}
#navbar-administration .navbar-tray .navbar-active-trail > .navbar-box .navbar-menu-item:before,
#navbar-administration .navbar-tray .navbar-active:before,
#navbar-administration .navbar-tray .active:before {
	opacity: .8;
}


#user-login,
#user-pass {
	border: none;
	padding: 0;
	max-width: 800px;
	.form-actions a {
		background-color: $light_grey;
		&:hover {
			background-color: #0585d0;
		}
	}

}
p.login-title {
	font-weight: $bold;
	font-size: 1em;
	text-transform: uppercase;
	letter-spacing: .06em;
	color: $dark_grey;
}
* + p.forgot-password {
	//margin-top: 0;
	vertical-align: top;
	margin-top: 2.6em;
	font-size: .8rem;
}

.profile {
	@include clearfix();
}

.filter-wrapper .form-item label,
.filter-help,
.filter-guidelines {
	display: none;
}
.ckeditor_links {
	font-size: 11px;
	color: $lime_green;
}




/*! ====== DASHBOARD THINGS */
body.page-user .region-content {
	.user-profile + .block,
	.block + .block {
		margin-top: 3em;
	}
}

.dashboard {
	th,
	td {
		vertical-align: baseline;
		padding: 1em 0;
		border-top: 1px solid $lightest_grey;
		border-bottom: 1px solid $lightest_grey;
		& + td,
		& + th {
			padding-left: 20px;
		}
		&.date,
		&.views-field-comment-count,
		&.views-field-last-updated {
			min-width: 10em;
			font-size: 80%;
		}
		&.icon {
			vertical-align: middle;
			width: 40px;
		}
	}

	th {
		font-size: 80%;
	}
	td.views-field-field-svg {
		width: 40px;
		vertical-align: middle;
		padding: 0;
	}
	.views-row {
		padding: .75em 0;
		& + .views-row {
			border-top: 1px solid $lightest_grey;
		}
	}
	.views-field-view-comment,
	.views-field-replyto-comment {
		a {
			font-size: 80%;
			font-weight: $bold;
			color: $lime_green;
		}
	}
	.view-content h2.block__title {
		margin-bottom: 0;
	}
	.views-row + h2.block__title {
		margin-top: 1.75em;
	}
}

.view-activity-feed {
	h3 {
		padding-left: 60px;
		text-indent: -60px;
		font-size: 1rem;
		margin: 2em 0 0;
		font-weight: $normal;
		img {
			vertical-align: middle;
			padding-right: 20px;
		}
	}
	.views-row {
		margin-left: 60px;
	}
}


/*! ====== CKEDITOR */
.cke_inner {
.cke_top,
.cke_bottom {
	background: $lightest_grey;
}
}
.cke.cke_chrome {
	border: none;
}

p.skip-link__wrapper {
  margin: 0;
}

.visually-hidden,
.visually-hidden--focusable,
.element-focusable,
.element-invisible,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}


.inline-sibling, .field-label-inline .field-label, span.field-label {
  margin-right: 10px;
}

ul.inline.links {
  padding: 0;
  list-style: none;
}
.inline-links__item, .inline.links li {
  display: inline;
list-style-type: none;
padding: 0 1em 0 0;
}

.hidden, html.js .js-hidden, html.js .element-hidden, html.js .js-hide {
  display: none;
}


ul.pager {
  margin: 40px 0;
  clear: both;
  text-align: center;
  padding: 0;
  list-style: none;
  li {
    display: inline;
    padding: 0 .5em;
  }
}
