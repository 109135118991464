main {
	@include maxwidth(800px, 40px, 20px);
	min-height: 400px;
	padding-top: 130px;
	.title-summary + & {
		padding-top: 25px;
	}
	padding-bottom: 70px;
	position: relative;
	z-index: 90; // puts it below the header
	body.front & {
		padding-top: 0;
		min-height: 0;
		max-width: 100%;
	}
	@media #{$mobile} {
		padding-top: 20px;
	}
	body.node-type-project & {
		padding-bottom: 0;
	}
}



body.front .region-content {
		@include maxwidth(1200px, 40px, 20px);
		@media #{$mobile} {
  		padding-left: 0;
  		padding-right: 0;
		}
}
body.page-research,
//body.page-partnerships,
body.page-about-core-partners {
	main {
		@include maxwidth(1200px, 40px, 20px);
	}
}

body.page-partners main,
body.page-partnerships main {
	max-width: 100%;
	padding-bottom: 0;
}
@media #{$tablet_up} {
body.page-partnerships main {
	.view-partnerships {
		.view-header {
			@include maxwidth(800px, 40px, 20px);
		}
		.view-filters-content {
			@include maxwidth(1200px, 40px, 20px);
		}
	}
}

body.page-partners main {
	.view-partnerships {
		.view-header {
			@include maxwidth(800px, 40px, 20px);
		}
		.view-filters {
			@include maxwidth(1200px, 40px, 20px);
		}
	}
}
}

body.page-research main {
	padding-top: 80px;
}
	body.page-comment .region-content {
		padding: 40px;
		background: $background;
	}


.region-bottom {
	//@include maxwidth(1200px, 40px, 20px);
}
/*
body.not-front.node-type-page,
body.node-type-article,
body.node-type-contact-page,
body.node-type-blog,
body.page-blog,
body.page-user,
body.node-type-partner,
body.node-type-webform {
	main {
		@include maxwidth(800px, 40px, 20px);
	}
}*/

header[roll="banner"] {
	width: 100%;
	padding-top: 98px;
}


aside.region-sidebar-first {
	position: fixed;
	top: 0;
	height: 100%;
	z-index: 1001;
	right: -390px;
	width: 390px;
	@media #{$mobile} {
		right: -100%;//-300px;
		width: 100%;//300px;
	}
	//padding: 0 40px 0 90px;
	box-sizing: border-box;
	background: #fff;
	border-left:1px solid $lightest_grey;
	-webkit-transition: right .25s ease-in-out;
	-moz-transition: right .25s ease-in-out;
	-ms-transition: right .25s ease-in-out;
	transition: right .25s ease-in-out;
	body.navbar-administration &,
	body.navbar-tray-open.navbar-vertical & {
		top: 39px;
	}
	body.navbar-tray-open.navbar-horizontal & {
		top: 79px;
	}
	body.show-menu & {
		right: 0;
	}
	.inner {
		height: 100%;
		overflow: auto;
		padding: 40px 40px 40px 70px;
		@media #{$mobile} {
			padding: 40px 20px;
		}
	}
	a.toggle-menu {
		display: inline-block;
		height: 20px;
		width: 20px;
		position: relative;
		float: right;
		&:before,
		&:after {
			content: '';
			border-top: 1px solid;
			display: block;
			width: 100%;
			position: absolute;
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
			left: 0;
			top: 8px;
		}
		&:after {
			content: '';
			border-top: 1px solid;
			display: block;
			width: 100%;
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	}
}

nav.region-navigation {
	position: fixed;
	background: #fff;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	font-size: 80%;
	text-transform: uppercase;
	line-height: 34px;
	height: 100px;
	padding: 33px 0;
	-webkit-transition: height .35s, padding .35s;
	-moz-transition: height .35s, padding .35s;
	-ms-transition: height .35s, padding .35s;
	transition: height .35s, padding .35s;
	body.scrolled & {
		height: 60px;
		padding: 13px 0;
		border-bottom: 1px solid $lightest_grey;
	}
	@media #{$mobile} {
		height: 60px;
		padding: 13px 0;
	}
	.inner {
		//max-width: 1200px;
		padding: 0 40px;
		margin: 0 auto;
		@media #{$mobile}{
			padding: 0 20px;
		}
	}
	body.navbar-administration &,
	body.navbar-tray-open.navbar-vertical & {
		top: 39px;
	}
	body.navbar-tray-open.navbar-horizontal & {
		top: 79px;
	}
	.logo-wrapper {
		@include maxwidth(1200px,0,0);
	}
	a.logo {
		float: left;
		display: block;
		margin: 0;// 10px;
		img {
			max-width: 200px;
			max-height: 35px;
			display: inline-block;
			//margin-right: 20px;
			vertical-align: bottom;
			//position: relative;
			//top: -7px;
			//border-right: 1px solid $lightest_grey;
			//padding-right: 20px;
			@media #{$mobile} {
				max-width: 150px;
			}
		}
		text-transform: none;
		font-weight: $bold;
		color: $mid_grey;
		&.wordmark {
			margin-left: 16px;
			svg path {
				fill: $light_grey;
			-webkit-transition: fill .25s ease-in-out;
			transition: fill .25s ease-in-out;
			}
			&:hover {
				svg path {
					fill: $blue;
				}
			}
		}
	}
	a.toggle-menu {
		float: right;
		display: block;
		margin: 0 10px 0 30px;
		font-weight: $bolder;
		@media #{$mobile}{
			margin: 0 10px 0 0;
		}
		span {
			display: inline-block;
			height: 9px;
			width: 1.5em;
			border-top: 1px solid;
			border-bottom: 1px solid;
			position: relative;
			margin-left: 1em;
			&:before {
				content:'';
				display: inline-block;
				width:100%;
				border-bottom: 1px solid;
				position: absolute;
				top: 3px;
			}
		}
	}
	.block-menu {
		float: right;
		ul.menu {
			margin: 0;
			li {
				display: inline;
				a {
					display: inline-block;
					padding: 0 10px;
					color: $mid_grey;
					&:hover {
						color: $lime_green;
					}
				}
				& + li {
					padding-left: 10px;
				}
			}
		}
	}
}

body.inner-sidebar {
	main {
		@include clearfix();
	}

	div.region-inner-sidebar {
		margin-top: 80px;
	}
	@media #{$tablet_up} {
		div.region-content {
			float: left;
			width: 60%;
		}
		div.region-inner-sidebar {
			float: right;
			width: 32%;
			margin-top: 0;
		}
	}
}

section.title-summary {
	background: #fff;
	padding-top: 130px;
	body.front &,
	body.node-type-page  & {
		//padding-bottom: 80px;
	}
	body.page-research & {
		padding-bottom: 80px;
	}

	//body.front & {
		//padding-top: 50px !important;
	//}
	@media #{$mobile} {
		padding-top: 80px;
		padding-bottom: 20px;
	}
	.inner {
		@include maxwidth(800px,40px,20px);
		body.front & {
			max-width: 1200px;
		}
		position: relative;
		@media #{$mobile} {
			padding: 0 20px;
		}
	}
}


.breadcrumb {
	h2.breadcrumb__title {
		display: none;
	}
	ol.breadcrumb__list {
		list-style: none;
		margin: 0;
		padding: 0;
		font-size: 12px;
		li {
			display: inline;
		}
	}
}



div.region-inner-sidebar {
	font-size: 80%;
}




.region-content-bottom {
	background: $background;
	.block {
		@include maxwidth(1200px,40px,20px);
		//@media #{$tablet_up} {
			padding-top: 80px;
		//}
		&.block-fieldblock  {
			max-width: 100%;
			padding: 40px;
		}
		&.core-partners-map {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
.region-featured {
	background: #fff;
	.inner {
		@include maxwidth(1200px,40px,20px);
		box-sizing: border-box;
		padding-top: 80px;
		padding-bottom:80px;
	}
}

footer.page__footer {
	background: linear-gradient($background 0%, #ffffff 50px);
	padding: 40px 0;
	position: relative;
	z-index: 5;
	.inner {
		max-width: 1200px;
		padding: 0 40px;
		@media #{$mobile} {
			padding: 0;
		}
		margin: 0 auto;
	}
}

.region-bottom {
	@include clearfix();
	padding: 80px 0 40px;
	@media #{$mobile} {
		padding: 20px;
	}
	font-size: 80%;
	.block + .block {
  	margin-top: 40px;
	}
	.xblock,
	.region{

		float: right;
		width: 50%;
		@media #{$narrow} {
			width: 30%;
		}
		&-bottom-col-3,
		&.last {
			width: 30%;
			@media #{$narrow} {
				width: 50%;
			}
			float: left;
		}
		&-bottom-col-1,
		&.first {
			width: 20%;
			p + p {
				margin-top: 40px;
			}
		}
		@media #{$mobile} {
			&,
			&.first,
			&.last {
				float: none;
				width: auto;
				margin: 3em auto 0;
			}
		}
		&-bottom-col-1,
		&.first {
			text-align: right;
			@media #{$mobile} {
				text-align: left;
			}
		}
		img {
			vertical-align: middle;
		}

		@media #{$mobile} {
			float: none;
			clear: both;
			width: auto;
			//margin: .5em auto;
		}

		&-bottom-col-3 p,
		&.last p {
			//padding-left: 34px;
			//text-indent: -34px;
			& + p {
				margin-top: 40px;
				@media #{$mobile} {
  				margin-top: 0;
				}
			}
			img {
				//display: none;
				max-width: 80%;
				display: inline-block;
				@media #{$mobile} {
  				margin: 0 0 2em 0;
				}
				@media #{$narrow} {
  				max-width: 200px;
				}
				height: auto;
			}/*
			&.logo-tagline {
				img {
					//max-width: 140px;
					//padding-right: 12px;
					//margin-right: 12px;
					//border-right: 1px solid $lightest_grey;
					display: inline-block;
					//position: relative;
					//top: -3px;
				}
				color: $mid_grey;
				font-weight: 400;
			}*/
			&:last-child {
				margin-top: 40px;
				@media #{$tablet_up} {
					margin-top: 80px;
				}
				font-size: .6rem;
			}
		}
		p {
			line-height: 1.4;
		}
	}
	ul.menu {
		//text-align: center;
		//columns: 2;
		margin: 0;
		padding: 0;
	}
	ul.menu li {
		display: block;
		text-transform: uppercase;
		padding-right: 1.5em;
	}
	.block-apec-helpers {
  	max-height: 380px;
  	-webkit-overflow-scrolling: touch;
  	overflow: auto;
	}
}

