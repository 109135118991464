@media #{$mobile} {
	nav.region-navigation {
		.block-menu {
			display: none;
		}
	}
}

.region-inner-sidebar {
	.block + .block {
		margin-top: 3.5em;
	}
}

.region-content {
	.block + .block,
	article.node + .block {
		margin-top: 2em;
	}
}



// Featured Project:
.views__front-block_2.block.block-views,
.views__front-block_3.block.block-views {
	@media #{$narrow} {
		max-width: 80%;
	}
	margin: auto;
	position: relative;
	@media #{$mobile} {
		max-width: 100%;
		margin-left: 0;
		margin-right: 0;
		h2.block__title {
			margin-left: 20px;
			margin-right: 20px;
		}
	}
	.views-row {
		background: $background;
		border-radius: 5px;
		position: relative;
		@include clearfix();
		body.front & {
			opacity: 0;
			-webkit-transition: opacity .3s;
			-moz-transition: opacity .3s;
			-ms-transition: opacity .3s;
			transition: opacity .3s;
			position: absolute;
			top: 0;
			z-index: 1;
			&.active {
				z-index: 2;
				opacity: 1;
				position: relative;
			}
		}
	}
	.pager {
		position: relative;
		z-index: 20;
		text-align: center;
		margin: 20px auto;
		span {
			display: inline-block;
			height: 11px;
			width: 11px;
			border-radius: 100%;
			border: 1px solid $light_grey;
			cursor: pointer;
			& + span {
				margin-left: 11px;
			}
			&.active {
				background: $light_grey;
			}
		}
	}
	.image {
		display: none;
		@media #{$narrow} {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			width: 33.3333%;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			& + .views-fieldset {
				margin-left: 33.3333%;
			}
		}
	}
	.views-field-field-image img {
		display: block;
	}
	.views-fieldset {
		padding: 2em;
		@media #{$mobile} {
			padding: 1em;
			.views-field-body {
				display: none;
			}
		}
		@media #{$narrow} {
			padding: 2em 4em;
		}
	}
	.views-field-field-focus-area {
		font-size: 80%;
	}
	h2.views-field-title {
		margin: 1em 0 .5em 0;
		font-weight: $normal;
		a.active {
			color: inherit;
		}
		@media #{$mobile} {
			font-size: 1.2em;
		}
	}

	.views-field-field-author {
		font-size: 80%;
		text-transform: uppercase;
		color: $light_grey;
		margin: .5em 0;
	}
	.more,
	.views-field-view-node {
		font-size: 80%;
		text-transform: uppercase;
		margin: 1.5em 0;
		letter-spacing: .06em;
		a {
			padding: 6px 0;
			display: block;//inline-block;
			position: relative;

			span {
				border-bottom: 1px solid $lightest_grey;
				display: inline-block;
				position: relative;
				&:after {
					content: '';
					display: block;
					position: absolute;
					bottom: 0;
					border-bottom: 1px solid $lime_green;
					width: 0;
					-webkit-transition: width .25s;
					-moz-transition: width .25s;
					-ms-transition: width .25s;
					transition: width .25s;
				}
			}
			&:hover span:after {
				width: 100%;
			}
			&.svg svg {
				border-bottom: none;
				float: right;
				padding: 6px 40px 6px 40px;
				-webkit-transition: padding .25s;
				-moz-transition: padding .25s;
				-ms-transition: padding .25s;
				transition: padding .25s;
				height: 24px;
				&:after {
					display: none;
				}
			}

				&:hover svg {
					padding: 6px 26px 6px 54px;
				}
		}
	}
	@include clearfix();
}

h2.block__title {
	font-size: 1.15em;
	text-transform: uppercase;
	font-weight: $bold;
	margin: 0 0 1.75em 0;
	.region-content & {
		color: $lime_green;
		letter-spacing: .08em;
		line-height: 1.3;
		font-size: 1em;
		font-weight: $bold;
	}
	body.front .region-content &,
	body.page-research .region-content & {
		margin-left: 70px;
		@media #{$mobile} {
			margin-left: 0;
		}
	}
	.region-inner-sidebar & {
		text-transform: none;
	}
	.block.dashboard & {
		font-weight: $light;
		font-size: 1.5em;
		text-transform: none;
		color: $body;
		margin-bottom: 1em;
	}
}


.region-inner-sidebar {

	.views-summary-unformatted {
		border-top: 1px solid $lightest_grey;
		border-bottom: 1px solid $lightest_grey;
		padding: 5px 0;
		& + .views-summary-unformatted {
			border-top: none;
		}
		a {
			padding-right: .5em;
		}
	}


	.view-blogs.related-posts {
		.views-row {
			display: table;
		}
		.views-row + .views-row {
			margin-top: 1.5em;
		}
		a {
			display: table-cell;
			vertical-align: middle;
			//width: 20%;
			line-height: 1.4;
		}
		a + a {
			padding-left: .75em;
			width: 80%;
		}
	}
}



/*! ====== SLIDE OUT REGION */
.region-sidebar-first {
	.block + .block {
		margin-top: 40px;
	}
	.block-menu-block,
	.block-menu {
		font-size: 80%;
	}
	li.userregister--2 {
		display: none;
	}
	ul.menu {
		list-style: none;
		padding: 0;
		li {
			margin: 0;
			list-style: none;
			line-height: 1.3;
			ul {
				margin: 1em 0 2.25em 2em;
			}
			& + li {
				margin-top: 1em;
			}
			&.depth-1 + .depth-1 {
				margin-top: 2em;
			}

			&.about.depth-1 {
				margin-top: 30px;
				padding-top: 30px;
				border-top: 1px solid $light_grey;
			}
			&.training-centre {
				a {
					color: $light_grey;
				}
			}
			&.depth-2 {
				list-style: disc;
				color: transparent;
				&.active-trail {
					color: $lime_green;
				}
			}
		}
		a {
			color: $body;
			&:hover {
				color: $light_grey;
			}
			&.active,
			&.active-trail {
				color: $lime_green;
			}
		}
	}
	a.depth-1 {
		text-transform: uppercase;
		position: relative;
		&:after {
			position: absolute;
			bottom: -3px;
			content: '';
			display: block;
			width: 0%;
			border-bottom: 1px solid $lime_green;
			-webkit-transition: width .25s;
			-moz-transition: width .25s;
			-ms-transition: width .25s;
			transition: width .25s;
		}
		&:hover:after {
			width: 100%;
		}
	}
}


.block-views .view-funders {
	.table {
		border-spacing: 3px;
	}
	.td {
		background: #fff;
		vertical-align: middle;
		text-align: center;
		@media #{$tablet_up} {
			height: 120px;
		}
		.views-fieldset {
			text-align: left;
			h2 {
				font-size: 1em;
				font-weight: 600;
				color: $blue;
				text-transform: uppercase;
			}
		}
	}
}


.search__form {
	form {
		position: relative;
		input[type="text"],
		input[type="search"] {
			width:100%;
			padding-right: 40px;
		}
		input[type="submit"] {
			position: absolute;
			top: 0;
			right: 0;
			width: 40px;
			height: 40px;
			display: block;
			text-indent: -9999px;
			border: none;
			border-left: 3px solid #fff;
			border-radius: 0;
			background: $background url(../images/search.svg) center center no-repeat;
			&:active {
				background: #dfefca url(../images/search-on.svg) center center no-repeat;
			}
			&:focus {
				outline: none;
			}
		}
	}
}


a .fa {
  color: $light_grey;
	&:hover {
		color: $lime_green;
	}
	font-size: 16px;
	margin-right: 1em;
	.comment & {
		padding-right: 2px;
	}
	//@media #{$tablet_up} {
		.region-bottom & {
			//padding-right: 0;
			padding: 0;
      height: 36px;
      width: 36px;
      background: $navy;
      border-radius: 36px;
    	color: #fff;
    	font-size: 20px;
    	margin-right: 12px;
    	text-align: center;
    	line-height: 36px;
    	&:hover {
    		background: $lime_green;
    	}
		}
	//}
}
