body {
	color: $body;
	background: #fff;
	&.front,
	&.page-research {
		background: #f9f9f9;
	}
	font-family: 'Pragmatica W01', Pragmatica, "Pragmatica Web", pragmatica-web, Helvetica, Arial, sans-serif;
	font-weight: $normal;
}

a {
	text-decoration: none;
	color: $link;
	transition: color .25s ease-in-out;
	// p & {letter-spacing: .06em;}
	&.active {
		color: $lime_green;
	}
	&:hover {
		color: $light_grey;
	}
}

p {
	line-height: 1.8;
}

b {
	font-weight: $bold;
}
strong,
label,
.field-label,
.field .field-label,
.views-exposed-form label {
	font-weight: $bold;
}
label {
	color: $dark_grey;
	text-transform: uppercase;
	.form-checkboxes &,
	.form-type-checkbox &,
	.form-radios & {
		text-transform: none;
		font-weight: $normal;
	}
	.form-item-notify-type & {
		font-size: 80%;
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: $light;
}
h1,h2,h3,h4,h5,h6 {
	line-height: 1.3;
}

h1.page__title {
	//font-family: "adobe-garamond-pro", Garamond, Times, serif;
	font-size: 2.6em;
	body.node-type-project &,
	body.node-type-article & {
		font-size: 1.5rem;
	}
	body.node-type-project & {
		font-weight: 200;
	}
	color: $dark_grey;
	line-height: 1.3;
	@media #{$mobile} {
		font-size: 1.6em;
	}
}
h1#page-title {
	display: block;
}

figcaption {
	font-size: .8rem;
	margin: .75em 0 1.5em;
	color: $light_grey;
}

.field-name-body h2 {
	font-weight: $bold;
	color: $lime_green;
	text-transform: uppercase;
	font-size: 110%;
	letter-spacing: .06em;
}
.field-name-body * + h2 {
	margin-top:3em;
}

.field-name-body *:first-child {
	margin-top: 0;
}

.field-name-body dl,
.view-content dl,
.view-header dl {
	border-top: 1px solid $lightest_grey;
	border-bottom: 1px solid $lightest_grey;
}
dd dl {
	border-top: none;
	border-bottom: none;
}

* + dl {
	margin-top: 3em;
}
dt {
	cursor: pointer;
	padding: 1.5em 40px 1.5em 0;
	font-weight: $light;
	line-height: 1.3;
	.body &,
	.view-content &,
	.view-header & {
		font-size: 150%;
	}
	position: relative;
	.svg {
		position: absolute;
		right: 0;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	svg {
		rect {
			fill: $lime_green !important;
		}
		line,path,polyline {
			stroke: $lime_green !important;
		}
		-webkit-transition: -webkit-transform .2s;
		-moz-transition: -moz-transform .2s;
		-ms-transition: -ms-transform .2s;
		transition: transform .2s;
		/*line.horizontal {
			-webkit-transition: stroke .25s;
			-moz-transition: stroke .25s;
			-ms-transition: stroke .25s;
			transition: stroke .25s;
		}*/
	}
	//}
	&:hover svg {
		line,path {
			stroke: $light_grey !important;
		}
		rect {
			fill: $light_grey !important;
		}
	}
	&.active svg {
		-webkit-transform: rotate(135deg);
		-moz-transform: rotate(135deg);
		-ms-transform: rotate(135deg);
		transform: rotate(135deg);
	}
}
* + dt {
	//margin-top: 1em;
	border-top: 1px solid $lightest_grey;
}
dd {
	max-height: 0;
	.field-name-body & {
		line-height: 1.8;
	}
	margin: 0 0 0 0;
	-webkit-transition: max-height .15s ease-in-out;//, overflow 0;
	-moz-transition: max-height .15s ease-in-out;//, overflow 0;
	-ms-transition: max-height .15s ease-in-out;//, overflow 0;
	transition: max-height .15s ease-in-out;//, overflow 0;
	overflow-y: hidden;
	overflow-x: visible;
	dt.active + & {
		max-height: 4000px;
		padding-bottom: 2em;
		//overflow:visible;
		-webkit-transition: max-height .35s ease-in-out, padding-bottom .35s ease-in-out, overflow 0 5.35s;
		-moz-transition: max-height .35s ease-in-out, padding-bottom .35s ease-in-out, overflow 0 5.35s;
		-ms-transition: max-height .35s ease-in-out, padding-bottom .35s ease-in-out, overflow 0 .35s;
		transition: max-height .35s ease-in-out, padding-bottom .35s ease-in-out, overflow 0 5.35s;
	}
	.body & dt {
		font-size: 1rem;
		border-top: none;
		padding-top: .75rem;
		padding-bottom: .75rem;
		font-weight: $bold;
	}
}

dl.faq {
	dt {
		font-size: 1em;
		padding-left: 1.5em;
		font-weight: $bold;
		&:before {
			content: 'Q';
			color: $lime_green;
			font-weight: $bold;
			position: absolute;
			left: 0;
		}
	}
	dd {
		padding-left: 1.5em;
		position: relative;
		&:before {
			content: 'A';
			color: $blue;
			font-weight: $bold;
			position: absolute;
			left: 0;
		}
	}
}


ul.pager {
	margin: 40px auto;
	li.pager-current {
		color: $lime_green;
		font-weight: normal;
	}
}

a,
h1,
h2,
h3,
h4,
h5,
h6 {
	img,
	svg {
		vertical-align: text-bottom;
	}
}

iframe {
  border: 0 !important;
}



img[style*="float:left"],
img.image-left {
	float: left;
	margin: 0 2em 2em 0;
	max-width: 150px;
	height: auto !important;
}

/*! ====== CONTACT PAGE */
.field_page_bottom {
	@include clearfix();
	padding: 2em 0;
	dt.active + dd {
		padding-right: 0;
		margin-right: 0;
	}
	dt {
		font-size: 150%;
	}
	h2 {
		clear: both;
		//font-size: .8rem;
		font-weight: $bolder;
		text-transform: uppercase;
		color: $lime_green;
		margin: 0;
		padding: 2em 0 1em;
		letter-spacing: 0.06em;
	}
	blockquote {
		background: $background;
		margin: 0;
		padding: 0;
		@include clearfix();
	}
}




.article__post_date,
.views-field-created,
p.submitted {
	font-size: 12px;
	//text-transform: uppercase;
	letter-spacing: .06em;
	font-weight: $bold;
	margin: 0 0 1.5em 0;
}

.article__post_date,
.views-field-created {
	//color: $lime_green;
}

.article__post_date,
p.submitted {
	//line-height: 48px;
	vertical-align: middle
}

p.submitted span,
p.submitted mark {
	vertical-align: middle;
	display: inline-block;
}

.article__share {
	//text-align: center;
	margin: 2em 0;
}

.node-discussion .field-name-body {
}

.comment__section {
	margin: 2em 0;
	.user-profile + h2.block__title {
		margin-top: 2em;
		margin-bottom: .25em;
	}
}
form.comment-form {
	.form-item.form-type-item[id*="author"] {
		float: left;
		width: 15%;
		& + .field-name-comment-body {
			float: right;
			width: 82%;
			margin-top: 0;
		}
	}
	.form-item-notify {
		clear:both;
	}
}

.indented {
	margin-left: 30px;
	&.indented-2 {
		margin-left: 60px;
	}
	&.indented-3 {
		margin-left: 90px;
	}
	&.indented-4 {
		margin-left: 120px;
	}
	&.indented-5 {
		margin-left: 150px;
	}
}

article.comment {
	.submitted {
		color: $light_grey;
	}
	@include clearfix();
	border-top: 2px solid $lightest_grey;
	.indented & {
		border-top: 1px solid $lightest_grey;
	}
	&.first {
		border-top: none;
	}
	margin-top: .5em;
	padding-top: .5em;
	mark {
		//float: right;
		color: $blue;
	}
	&.comment-by-node-author {
		background: #EFEEEB;
		padding: 20px;
		border-top: 0;
		border-bottom: 0;
	}
	footer.user-signature {
		font-size: 80%;
		margin: 1em 0 0 0;
		padding: .5em 0 0;
		border-top: 1px solid #EFEEEB;
	}
	& + .indented & {
		border-top: none;
	}
}

ul.links.inline {
	margin: 1em 0 0;
	//float: right;
	font-size: 80%;
	text-transform: uppercase;
	a {
		color: $lime_green;
	}
li.comment_forbidden {
	display: none;
}
}


.region-content .view-blogs .views-row + .views-row,
.node-blog + .node-blog {
		margin-top: 2em;
		padding-top: 2em;
		border-top: 1px solid $lightest_grey;
}

article.node-blog {
	//@include maxwidth(800px);
	.field_tags {
		margin: 3em 0 1em;
		padding: 2em 0 0;
		border-top:1px solid $lightest_grey;
		font-size: 80%;
		a {
			display: inline-block;
			background: $background;
			padding: 2px 12px;
			color: #BCBEC0;
			& + a {
				margin-left: 1em;
			}
			&:hover {
				background: $lime_green;
				color: #fff;
			}
		}
	}
	.share {
		margin: 3em 0;
		padding: 2em 0;
		border-top: 1px solid $lightest_grey;
		border-bottom: 1px solid $lightest_grey;
		.field-label {
			text-transform: uppercase;
			font-size: 80%;
		}
	}
	.field_tags + .share {
		margin: 1em 0 3em;
		padding: 0 0 1em;
		border-top: none;
	}
}

.user-profile {
	font-size: .8rem;
	@include clearfix();
	.ds_user_picture img,
	.user_picture img {
		display: block;
	}
	&.view-mode-profile {
		display: table;
		margin: 1.5em 0;
		.group-left,
		.group-right {
			float: none;
			display: table-cell;
			vertical-align: middle;
		}
		.group-right {
			padding-left: 1.5em;
		}
	}
	.group-left,
	.group-right {
		float: none;
	}
	&.has-right .group-left {
		width: 15%;
		float: left;
	}
	&.has-left .group-right {
		width: 85%;
		float: right;
	}
	dd {
		max-height: 100%;
	}
	.user__field_fullname {
		//font-weight: 400;
	}
	.user__field_position {
		color: $light_grey;
	}
}


.reveal,
.block.reveal {
	opacity: 0;
	/*-webkit-transform: translateY(50%);
	-moz-transform: translateY(50%);
	-ms-transform: translateY(50%);
	transform: translateY(50%);*/
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	&.show {
		opacity: 1;
		/*
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);*/
	}
	& + .reveal {
		-webkit-transition-delay: .25s;
	}
	& + .reveal + .reveal {
		-webkit-transition-delay: .5s;
	}
	& + .reveal + .reveal + .reveal {
		-webkit-transition-delay: .75s;
	}
}
#cboxOverlay {
	position: fixed;
	top:0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	z-index: 1999;
}
#colorbox {
	box-shadow: 0 0 20px $lightest_grey;
	background: #fff;
	z-index: 2000;
	&:focus {
		outline: none;
	}
	button#cboxClose {
		background: url(../images/close.svg) center center no-repeat;
		text-indent: -9999px;
		height: 44px;
		width: 44px;
		position: absolute;
		top: -54px;
		right: -54px;
	}
}
#cboxLoadedContent {
	background: #fff;
	iframe {
		height: 100%;
		width: 100%;
	}
}
