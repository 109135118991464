// @file
// The major stylesheet for this theme.
//
// We categorize our components by creating headings in this file. See the
// description on the style guide home page for more information.


// Colors and Sass
// Style guide: sass
//@import 'init';

$navy: #042C4C;
$lime_green: #8BC34B;
$teal: #64ACAC;
$blue: #0C649C;
$light_blue: #84BCC4;
$grey: #6C7C94;
$link: #004f8e;

$body: #505050;
$background: #f9f9f9;

$mid_grey: #666666;
$dark_grey: #414042;
$light_grey: #A7A9AC;
$lightest_grey: #E6E7E8;


$mobile : "(max-width:600px)";
$tablet_up : "(min-width: 601px)";
$tablet : "(max-width:800px)";
$narrow : "(min-width:801px)";

$tab : $tablet_up;
$desk : "(min-width: 1025px)";


//$phone : "@media (max-width:600px)";
$short : "(max-height:900px)";

$light: 100;
$normal: 300;
$bold: 400;
$bolder: 500;
$black: 600;

@mixin clearfix() {
  &:after {
    content:'';
    display: block;
    clear: both;
  }
}


@mixin maxwidth($maxwidth : 1200px, $padding : 0, $padding_mobile : 0) {
	max-width: $maxwidth;
	margin-left: auto;
	margin-right: auto;
	padding-left: $padding;
	padding-right: $padding;
	box-sizing: content-box;
	@media #{$mobile} {
		padding-left: $padding_mobile;
		padding-right: $padding_mobile;
	}
}

@mixin button($color:'grey'){
	-webkit-appearance: none;
	display: inline-block;
	letter-spacing: .08em;
	vertical-align: bottom;
	color: #fff;
	font-size: .8rem;
	text-transform: uppercase;
	padding: .5em 3em .4em;
	background: $teal;//$background;
	-webkit-transition: background .25s;
	-moz-transition: background .25s;
	-ms-transition: background .25s;
	transition: background .25s;
	border-radius: 5px;
	border: none;
	line-height: inherit;

	&:hover {
		background: $blue;
		color: #fff;
	}
	@if $color == 'white' {

		background: #fff;
		border:1px solid $light_grey;
		color: $light_grey;
		&:hover {
			background: $lime_green;//$light_grey;
			border:1px solid $lime_green;
			color: #fff;
		}
	} @else if $color == 'secondary' {
		background: $light_grey;
		&:hover {
			background: $blue;
		}
		* + & {
			margin-left: 2em;
		}
	} @else if $color == 'blue' {
		background: $blue;
		color: #fff;
		&:hover {
			background: $light_grey;
		}
	}

}

// Defaults
//
// These are the default base styles applied to HTML elements.
//
// Component classes can override these styles, but if no class applies a style
// to an HTML element, these styles will be the ones displayed.
//
// Style guide: base

// Ensure fonts get loaded first to minimize front-end performance impact.
  //@import 'base/fonts';
  //@import 'base/normalize';

// Components
// Style guide: components.navigation

  //@import 'components/autocomplete/autocomplete';
//@import 'components/box/box';
//@import 'components/breadcrumb/breadcrumb';
//@import 'components/button/button';
 // @import 'components/clearfix/clearfix';
    //@import 'components/collapsible-fieldset/collapsible-fieldset';
    //@import 'components/comment/comment';
    //@import 'components/divider/divider';
    //@import 'components/form-item/form-item';
//@import 'components/form-table/form-table';
  //@import 'components/header/header';
  //@import 'components/hidden/hidden';
  //@import 'components/highlight-mark/highlight-mark';
  //@import 'components/inline-links/inline-links';
  //@import 'components/inline-sibling/inline-sibling';
  //@import 'components/messages/messages';
  //@import 'components/more-link/more-link';
  //@import 'components/nav-menu/nav-menu';
  //@import 'components/navbar/navbar';
  //@import 'components/pager/pager';
//@import 'components/print-none/print-none';
  //@import 'components/progress-bar/progress-bar';
  //@import 'components/progress-throbber/progress-throbber';
//@import 'components/resizable-textarea/resizable-textarea';
//@import 'components/responsive-video/responsive-video';
  //@import 'components/skip-link/skip-link';
//@import 'components/table-drag/table-drag';
  //@import 'components/tabs/tabs';
  //@import 'components/visually-hidden/visually-hidden';
//@import 'components/watermark/watermark';
//@import 'components/wireframe/wireframe';

@import 'partials/normalize';
//@import 'drupal/system.base';
//@import 'drupal/system.menus';
//@import 'drupal/system.messages';
//@import 'drupal/system.theme';
@import 'partials/system';
@import 'partials/layout';
@import 'partials/blocks';
@import 'partials/globals';
@import 'partials/forms';
@import 'partials/front';
@import 'partials/projects';
@import 'partials/partnerships';
@import 'partials/pages';
