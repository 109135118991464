Xbutton,
.Xbutton,
a.download {
	-webkit-appearance: none;
	//background: none;
	color: inherit;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: $bold;
	letter-spacing: 0.08em;
	border-radius: 5px;
	-webkit-transition: color .25s;
	-moz-transition: color .25s;
	-ms-transition: color .25s;
	transition: color .25s;
	&.small {
		padding: 10px 10px 8px;
		line-height: 1;
	}
}
Xbutton,
X.button {
	padding: .8em 1.5em;
	background: $teal;
	color: #fff;
	border:none;
	&:hover {
		background: $blue;
	}
}
a.download {
	padding: .8em 1.5em;
	border:1px solid $lightest_grey;
	background: url(../images/download.svg) right 17px center no-repeat;
	padding-right: 40px;
	display:inline-block;
	svg {
		float: right;
		padding: 1px 0;
		margin: 4px 0;
	}
	&:hover {
		color: #fff;
		background-color: $lime_green;
		background-image: url(../images/download-on.svg);
		svg {
			polygon,
			rect {
				fill: #fff;
			}
		}
		//color: #fff;
		//background-color: $lime_green;
		//background-image: url(../images/download-on.svg);
	}
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="url"],
input[type="password"] {
	font-size: 16px;
	font-family: inherit;
	padding: .5em;
	box-sizing: border-box;
  background: url('../images/textfield.png') center top repeat-x;
  -webkit-transition: background-position .5s ease-in-out;
  -moz-transition: background-position .5s ease-in-out;
  -ms-transition: background-position .5s ease-in-out;
  transition: background-position .5s ease-in-out;
  border: none;
  line-height: inherit;
  &:focus {
	  outline: none;
	  background-position: center bottom;
  }
  &.throbbing {
	  background-position: right center;
	  background-repeat: no-repeat;
	  background-color: #dfefca;
  }

	& + label {
		visibility: visible;
		font-size: 11px;
		font-weight: $bold;
		color: $light_grey;
		letter-spacing: .07em;
	}
	&:not([value]) + label,
	&[value=""] + label {
		visibility: hidden;
	}
}

select + label,
select + label.option,
textarea + label,
.form-textarea-wrapper + label,
.form-managed-file + label {
	display: none;
}

input[type="checkbox"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	background: $background;
	border: 1px solid $lightest_grey;
	display: inline-block;
	width: .75em;
	height: .75em;
	vertical-align: baseline;
	-webkit-transition: background .2s;
	-moz-transition: background .2s;
	-ms-transition: background .2s;
	transition: background .2s;
	&:checked {
		background: $lime_green;
	}
}


#autocomplete {
	background: #fff;
	padding: 8px;
	border: 1px solid $lightest_grey;
	border-top: none;
	li.selected {
		color: $lime_green;
		cursor: pointer;
	}
}

.form-actions {
	margin: 1em auto;
}
button,
.button,
input[type="submit"],
.form-actions input,
.form-actions a,
.form-actions a:visited {
	@include button('grey');
	&.white {
		background: #fff;
		border:1px solid $light_grey;
		color: $light_grey;
		&:hover {
			background: $lime_green;//$light_grey;
			border:1px solid $lime_green;
			color: #fff;
		}
	}
	&.blue {
		background: $blue;
		color: #fff;
		&:hover {
			background: $light_grey;
		}
	}
	&.secondary {
		background: $light_grey;
		&:hover {
			background: $blue;
		}
		* + & {
			margin-left: 2em;
		}
	}
	&[value="Search"] {
		padding: .5em .25em .4em;
		width: 40px;
		height: 40px;
		display: block;
		text-indent: -9999px;
		border: none;
		border-left: 3px solid #fff;
		border-radius: 0;
		background: $background url(../images/search.svg) center center no-repeat;
		background-size: 16px;
		&:hover,
		&:active {
			background-color: #dfefca;
			background-image: url(../images/search-on.svg);
		}
		&:focus {
			outline: none;
		}
	}
	&:focus {
		outline: none;
	}
	.form-managed-file & {
		padding: 2px 10px 0px;
		font-size: 10px;
		font-weight: $bold;
		letter-spacing: .07em;
		background: $light_grey;
		&:hover {
			background: $blue;
		}
	}
}
.form-actions a {
	//color: $light_grey;
	//font-weight: 500;
}

.form-actions {
	clear: both;
	margin: 1.5em 0;
	input,
	a {
		margin-left: 2em;
		&:first-child {
			margin-left: 0;
		}
	}
}



::-webkit-input-placeholder {
   color: $light_grey;
}

:-moz-placeholder { /* Firefox 18- */
   color: $light_grey;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: $light_grey;
}

:-ms-input-placeholder {
   color: $light_grey;
}

.form-required {
	color: $lime_green;
}

fieldset {
	border:none;
	margin: 0;
	body.page-user & {
		border:1px solid $lightest_grey;
	}
	legend {
		font-size: 80%;
		body.page-user & {
			font-size: 100%;
			font-weight: $bold;
			color: $navy;
		}
		.region-inner-sidebar & {
			font-size: 100%;
		}
	}
	* + & {
		margin-top: 2em;
	}
}


textarea {
	background: $background;
	.comment-form & {
		background: #fff;
	}
	width: 100%;
	border: none;//1px solid $lightest_grey;
	-webkit-transition: background .2s;
	-moz-transition: background .2s;
	-ms-transition: background .2s;
	transition: background .2s;
	border: 1px solid $background;
	&:focus {
		background: #effae2;
		//border: 1px solid $lime_green;
	}
}

.filter-wrapper {
	padding: 0;
}

.form-item + .form-item,
* + .form-item {
  margin: 1.5rem 0 0;
}

.form-item.with-description {
	label {
		display: inline-block;
		width: 30%;
		vertical-align: top;
		line-height: 2.5;
	}
	.form-item-with-description {
		display: inline-block;
		width: 69%;
	}
	&.form-item-comment-notify {
		margin: .5rem 0;
		label {
			display: block;
			width: auto;
			line-height: inherit;
		}
		.form-item-with-description {
			display: block;
			width: auto;
		}
	}
}

.fieldset-description,
.form-item .description {
	margin: .5em 0 1em;
	line-height: 1.3;
}

.chosen-container-active a.chosen-single,
.chosen-container-single a.chosen-single,
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	border: none;//1px solid $lightest_grey;
	padding: 7px 0;
	font-size: 16px;
	font-family: inherit;
	border-radius: 0;
	//background: #fff url(../images/select.svg) right 10px center no-repeat;
	background: #fff url(../images/angle-circle-down.svg) right 10px center no-repeat;
	box-shadow: none;
	height: auto;
	color: $light_grey;
	min-width: 15em;
	&:focus {
		outline: none;
	}
	@media #{$mobile} {
		min-width: 10em;
		width: 100%;
		display: block;
	}
}
select {
	padding: 5px 40px 5px 0;//10px;
	border-bottom: 1px solid $lightest_grey;
	width: 100%;
	color: $body;
	//text-transform: uppercase;
	font-size: .8rem;
	line-height: 1;
	.page-user &,
	.webform-client-form & {
		font-size: 1rem;
		line-height: inherit;
		padding-top: .5em;
		padding-bottom: .5em;
	}
}
.chosen-container-active a.chosen-single,
.chosen-container-single a.chosen-single {
	background-image: url(../images/search.svg);
	background-size: 16px;
	background-color: $background;
	padding-left: 10px;
	border: 1px solid $background;
}


.chosen-container-single {
	width: 100% !important;
	margin: 0 0 1.5em 0;
	a.chosen-single {
		div {
			display: none;
		}
	}
	.chosen-drop {
		.chosen-results li.highlighted {
			background: $lime_green;
		}
	}
}
.chosen-container-active.chosen-with-drop a.chosen-single {
	background: inherit;
}

#entity-subscription-subscribe-form {
	.form-type-textfield label {
		display: none;
	}
}
.form-type-checkbox {
	padding-left: 20px;
	text-indent: -20px;
	input {
		margin-right: 5px;
	}
	* + & {
		margin-top: .25em;
	}
}


#simplenews-subscriptions-page-form {
	fieldset {
		padding: 0;
		margin: 1em 0;
	}
	.form-item.form-type-checkbox {
		margin: .5em 0;

	}
	input[type="text"] {
		width: 30em;
	}
	.form-item-mail label {
		display: none;
	}
	input[type="submit"] {
		margin-right: 2em;
		margin-bottom: 1em;
		min-width: 14em;
	}
	input[value="Unsubscribe"] {
		background: $mid_grey;
		&:hover {
			background: $light_grey;
		}
	}
}

thead th {
	text-align: left;
	font-weight: $bold;
	text-transform: uppercase;
}
form tbody tr {
	border-top:1px solid $lightest_grey;
}
form th,
form td {
	padding: .5em 1em .5em 0;
}

.bef-select-as-links {
	line-height: 1.2;
	.form-item + .form-item {
		margin-top: 1em;
	}
}


.ajax-progress.ajax-progress-throbber {
	background: url(../images/ajax-loader-8BC34B.gif);
	height: 32px;
	width: 32px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -16px 0 0 -16px;
	z-index: 101;
	*,
	img {
		display: none;
	}
}


.confirm-parent,
.password-parent {
	width: auto;
	clear:both;
}

.form-type-password-confirm .description {
	margin-left: 30.5%;
	@media #{$mobile} {
		margin-left: 0;
	}
}

.region-content .search-form {
	display: none;
}

p.forgot-password {
  text-align: right;
  max-width: 37.5em;
}
* + p.forgot-password {
  margin-top: .5em;
}
