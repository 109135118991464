body.front {
	background: $navy;
	section.title-summary {
		background: $navy;
		color: #fff;
		padding-top: 0;
		z-index: 5;
		position: relative;
		h1 {
			color: #fff;
			text-align: center;
			font-size: 2.5em;//3.5em;
			margin: 100px auto 10px auto;
			margin: 0 auto;
			padding: 100px 0 10px;
			//max-width: 80%;
				max-width: 1000px;
			@media (min-height:1000px) and (min-width:1400px){
				font-size: 3.5em;
			}
			font-weight: $light;
			line-height: 1.3;
			@media #{$mobile} {
				font-size: 1.5em;
				max-width: 100%;
			}
		}
	}
	main {
  	position: relative;
  	z-index: 5;
  	background: $navy;
	}
}





// Featured Page, Article:
header[role='banner'] {
	background: $grey;
	color: #fff;
	margin-top: 98px;
	@media #{$mobile} {
		margin-top: 50px;
	}

	.apec_helpers__apec_front_header {
		width: 100%;
		position: relative;
		@media #{$narrow} {
			//min-height: 580px;
		}
		.row {
			display: flex;
  		flex-direction: column;
  		@media #{$tab} {
    		flex-direction: row;
    		flex-wrap: nowrap;
  		}
		}
		h2 {
			font-weight: $normal;
			margin: 0;
			font-size: 1em;
  		@media #{$tab} {
    		font-size: 1.25em;
  		}
  		@media #{$desk} {
    		font-size: 1.5em;
  		}
		}
		h4 {
			text-transform: uppercase;
			font-weight: $bolder;
			font-size: 80%;
			a {
				float: right;
			}
		}
		section {
			display: flex;
			vertical-align: top;
			//height:  40vh;
			position: relative;
			overflow: hidden;
			&.promoted-page {
				min-height: 400px;
  			@media #{$tab} {
  				width: calc(100% - 350px);
				}
				article {
  				position: absolute;
  				top: 0;
  				left: 0;
  				width: 100%;
  				height: 100%;
  				display: flex;
  				flex-direction: column;
  				justify-content: flex-end;
  				background-position: center center;
  				background-repeat: no-repeat;
  				background-size: cover;
  				transform: translateX(100%);
  				transition: transform .5s ease-in-out;
  				z-index: 1;
  				&.active {
    				transform: translateX(0);
    				z-index: 2;
  				}
  				&.top {
    				z-index: 5;
  				}
				}
				.inner {
					bottom: 0;
					width: 100%;
					background-color: rgba(139,195,75,.90);
					transition: background-color .5s ease-in-out;
					div {
						top: 200px;
					}
				}
				&:hover  {
					.inner {
						background-color: rgba(139,195,75,1);//rgba(100,172,172,.95);
					}
				}

				.pager {
  				position: absolute;
  				bottom: 15px;
  				width: 100%;
  				text-align: center;
  				z-index: 10;
  				margin: 0 auto;
  				line-height: 1;
  				li {
    				display: inline-block;
    				background: #fff;
    				cursor: pointer;
    				&.active {
      				background: $body;
    				}
    				height: 15px;
    				width: 15px;
    				border-radius: 100%;
    				& + li {
      				margin-left: 15px;
    				}
  				}

				}
			}

			&.promoted-article {
				width: auto;
  			@media #{$tab} {
  				width: 350px;
				}
				background: $teal;
				.summary {
  				display: none;
  				@media #{$tab} {
    				display: block;
  				}
				}
				.inner {
					& > div {
						max-width: 450px;
						margin: 0 auto 0 0;
					}
				}

			}
			&.spacer {
				background: none;
				height: 40vh;
				&:hover {
					background: none;
				}
			}
		}
		@media #{$mobile} {
			section,
			section + section {
				display: block;
				width: 100%;
			}
		}
		.imgs {
			//height: 40vh;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			//position: relative;
			position: absolute;
			top:0;
			width: 100%;//66.6666%;//100%;
			height: 100%;
			@media #{$mobile} {
				height: 100%;//20vh;
			}
		}
		div.promoted-article .imgs {
			right: 0;
			width: 33.3333%;
		}
		.imgs .img {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			opacity: 0;
			-webkit-transition: opacity .5s;
			-moz-transition: opacity .5s;
			-ms-transition: opacity .5s;
			transition: opacity .5s;
			&.active {
				opacity: 1;
			}
		}
		.Ximgs {
			//height: 40vh;
		}
		.inner {
			padding: 20px 40px 20px 40px;
			overflow: hidden;
			-webkit-font-smoothing: antialiased;
			//-webkit-transform: translate3d(0,0,0);
			div {
				position: relative;
			}
			body.loaded & {
				//-webkit-transition: padding .35s ease-in-out .75s;
				//-moz-transition: padding .35s ease-in-out .75s;
				//-ms-transition: padding .35s ease-in-out .75s;
				//transition: padding .35s ease-in-out .75s;
				//padding: 20px 70px 25px 40px;
				div {
					top: 0;
					-webkit-transition: top .35s ease-in-out .75s;
					-moz-transition: top .35s ease-in-out .75s;
					-ms-transition: top .35s ease-in-out .75s;
					transition: top .35s ease-in-out .75s;
				}
			}
			//padding: 40vh 70px 25px 40px;
			& > div {
				max-width: 1200px;
				margin: 0 0 0 auto;
			}
			//background-color: rgba(100,172,172,.4);
			display: block;
			position: relative;
			@media #{$mobile} {
				padding: 15px 15px 25px;
			}
		}

		/*
			body.loaded & .promoted-article .inner div {
			-webkit-transition: top .35s ease-in-out 1.5s;
			-moz-transition: top .35s ease-in-out 1.5s;
			-ms-transition: top .35s ease-in-out 1.5s;
			transition: top .35s ease-in-out 1.5s;
		}
		*/
		a {
			color: #fff;
			-webkit-transition: color .35s;
			-moz-transition: color .35s;
			-ms-transition: color .35s;
			transition: color .35s;
			&:hover {
				color: rgba(255,255,255,.5);
			}
		}
		@media #{$mobile} {
			display: block;
			width: 100%;
			& + .block {
				width: 100%;
				border-left: none;
			}
		}
		p.more,
		div.more {
			display: block;
			margin: 20px 0 0;
			a {
				font-size: 80%;
				text-transform: uppercase;
				color: #fff;
				border-bottom: 1px solid rgba(255,255,255,.4);
				display: inline-block;
				padding: 6px 0;
				position: relative;
				&:after {
					content: '';
					display: block;
					position: absolute;
					bottom: -1px;
					right: 0;
					width: 100%;
					border-bottom: 1px solid #fff;
					-webkit-transition: width .25s;
					-moz-transition: width .25s;
					-ms-transition: width .25s;
					transition: width .25s;
				}
				&:hover {
					color: rgba(255,255,255,.6);
					&:after {
						width: 0%;
					}
				}
			}
		}
	}

	a.svg {
		display: none;
		position: absolute;
		right: -30px;
		@media #{$mobile} {
			right: 20px;
		}
		bottom: 50px;
		-webkit-transition: right .25s ease-in-out;
		-moz-transition: right .25s ease-in-out;
		-ms-transition: right .25s ease-in-out;
		transition: right .25s ease-in-out;
		padding-left: 40px;
	}
	a.svg:hover {
		right: -50px;
		@media #{$mobile} {
			right: 5px;
		}
	}
}

.apec_helpers__apec_front_video {
  height: calc(100vh - 98px);
  @media #{$mobile} {
    height: calc(100vh - 59px);
  }
  overflow: hidden;
  .cover {
    position: fixed;
    top: 100px;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    @media #{$mobile} {
      top: 59px;
    }
    &:after {
      content:'';
      display: block;
      position: absolute;
      top: -50px;
      left: 0;
      height: calc(100% + 50px);
      width: 100%;
      z-index: 1;
      background: rgba(0,0,0,.5);
    }
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -100;
      //-webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      //-webkit-transition: opacity 1s ease;
      transition: opacity 1s ease;
    }
    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      text-align: center;
      width: 90%;
      max-width: 20em;
      font-size: 3rem;
      @media #{$tablet} {
        font-size: 2rem;
      }
      @media #{$mobile} {
        font-size: 1.5rem;
        top: 40%;
      }
      z-index: 2;
      .toggle-front-video.open {
        display: block;
        margin: .5em auto;
        position: static;
        transition: transform .15s ease-in-out;
        img {
          //display: block;
          height: 60px;
          width: 60px;
        }
        &:hover {
          transform: scale(1.15);
        }
      }
    }
    .front-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;//100%;
      overflow: hidden;
      background: #000;
      opacity: 0;
      z-index: 0;
      //-webkit-transition: opacity .5s .25s ease-in-out;
      transition: opacity .5s .25s ease-in-out;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    span {
      position: absolute;
      bottom: 10%;
      left: 50%;
      text-transform: uppercase;
      transform: translateX(-50%);
      transition: bottom .15s;
      cursor: pointer;
      z-index: 2;
      &:hover {
        bottom: 8.5%;
        &:after {
          margin-top: -5px;
        }
      }
      &:after {
        content:'';
        display: block;
        background: url(../images/angle-circle-down.svg) center center no-repeat;
        background-size: 30px;
        height: 40px;
        width: 40px;
        margin: auto;
        transition: margin .15s;
      }
    }

    .toggle-front-video.close {
      position: absolute;
      top: 30px;//50px;
      right: 90px;//50px;
      z-index: 150;
      display: inline-block;
      height: 48px;
      width: 48px;
      opacity: 0;
      transition: opacity .25s;
      &:before,
      &:after {
        content:'';
        display: block;
        border-top: 1px solid #fff;
        width: 100%;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
      &:hover:before,
      &:hover:after {
        border-color: #fff;
      }
    }

    &.playing {
      //z-index: 11;
      .front-video {
        opacity: 1;
        z-index: 100;
        height: 100%;
        &:hover {
          .toggle-front-video.close {
            opacity: 1 !important;
          }
        }
        iframe:hover {
          & + .toggle-front-video.close {
            opacity: 1;
          }
        }
      }
    }
  }
}



.region-highlighted {
  background: $teal;//$grey;
  position: relative;
  z-index: 5;
}



section.block.apec_helpers__apec_front_updates {
  max-width: 100%;
  margin: auto;
  color: #fff;
  padding: 40px 20px;
  @media #{$tablet_up} {
    padding: 80px 0;
  }
  background: $teal;
  a {
    color: #fff;
    &:hover {
					color: rgba(255,255,255,.6);
    }
  }
  section.articles {
    @media #{$tablet_up} {
      display: flex;
      justify-content: space-between;
      width: 100%;//calc(100% + 80px);
    }
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
  }
  h4 {
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 0 1em;
    @media #{$tablet_up} {
      padding-left: 20px;
    }
    a {
      padding-left: 4em;
    }
  }


  article {
    padding: 20px 0;
    @media #{$tablet_up} {
      //display: table-cell;
      width: 31.5%;//33.3333%;
      padding: 10px 20px 60px;//0;
      position: relative;
    }
    //background-color: $teal;
    //transition: background-color .15s ease-in-out;
    &:hover {
     // background-color: $lime_green;
    }
    img,
    iframe {
      max-width: 100%;
    }
    img {
      height: auto;
    }
    .svg {
      display: none;
    }
    h2 {
      margin: .5em 0 0;//1em;
    }
  }

		p.more,
		div.more {
			display: block;
			margin: 0;//20px 0;
			position: absolute;
			bottom: 0;
			a {
				font-size: 80%;
				text-transform: uppercase;
				color: #fff;
				border-bottom: 1px solid rgba(255,255,255,.4);
				display: inline-block;
				padding: 6px 0;
				position: relative;
				&:after {
					content: '';
					display: block;
					position: absolute;
					bottom: -1px;
					right: 0;
					width: 100%;
					border-bottom: 1px solid #fff;
					-webkit-transition: width .25s;
					-moz-transition: width .25s;
					-ms-transition: width .25s;
					transition: width .25s;
				}
				&:hover {
					color: rgba(255,255,255,.6);
					&:after {
						width: 0%;
					}
				}
			}
		}

}


body.front {

	.front__body {
		opacity: .3;
		color: #fff;
		padding: 1em 0 0;
		a {
			color: $lime_green;//#fff;
			&:hover {
				color: $lime_green;
			}
		}
		p {
			max-width: 80%;
			margin: 0 auto 0;
			font-size: 130%;
			line-height: 1.7;
			font-weight: $light;
			letter-spacing: 0.06em;
			@media #{$mobile} {
				max-width: 100%;//95%;
				font-size: 110%;
			}
		}
	}
	//&.loaded .title-summary,
	&.loaded .front__body {
		padding: 0;
		-webkit-transition: padding .75s ease-in-out .2s,  opacity .5s ease-in-out .2s;
		-moz-transition: padding .75s ease-in-out .2s,  opacity .5s ease-in-out .2s;
		-ms-transition: padding .75s ease-in-out .2s, opacity .5s ease-in-out .2s;
		transition: padding .75s ease-in-out .2s, opacity .5s ease-in-out .2s;
		opacity: 1;
	}
}


body.front .region-content-bottom {
  position: relative;
  z-index: 6;
	.block-block {
		max-width: 100%;
		background: $navy;
		padding: 0px 30px 100px 30px;
		@media #{$mobile} {
			padding: 0 20px 50px 20px;
		}

		p {
			//max-width: 800px;
			margin: 0 auto;
			text-transform: uppercase;
			font-size: 120%;
			@media #{$mobile} {
				font-size: 1em;
			}
			@media #{$narrow} {
				text-align: center;
			}
			@media #{$narrow} {
				& > span {
					display: inline-block;
				}
				span + span {
					margin-left: 10px;
					span {
						padding-left: 30px;
						display: inline-block;
						border-left: 1px solid rgba(255,255,255,0);
						-webkit-transition: border-left .25s ease-in-out 2.5s;
						transition: border-left .25s ease-in-out 2.5s;
					}
					&.show {
						span {
							border-left: 1px solid rgba(255,255,255,.5);
						}
					}
				}
			}
			a {
				color: #fff;
				position: relative;
				min-height: 60px;
				line-height: 1.1;
				font-weight: $bolder;
				display: inline-block;
				padding: 10px 60px 10px 0;
				vertical-align: bottom;
				border-bottom: 1px solid rgba(255,255,255,.5);
				@media #{$tablet} {
					//max-width: 100%;
					min-height: 1.2em;
					margin: 0 auto;
					display: block;
					//text-align: center;
					br {
						display: none;
					}
				}
				&:hover {
					color: $light_grey;
				}
				@media #{$narrow} {
					max-width: 18em;
					border-bottom: none;
					& + a {
						margin-left: 10px;
						span {
							padding-left: 30px;
							display: inline-block;
							border-left: 1px solid rgba(255,255,255,0);
							-webkit-transition: border-left .25s ease-in-out 2.5s;
							transition: border-left .25s ease-in-out 2.5s;
						}
						&.show {
							span {
								border-left: 1px solid rgba(255,255,255,.5);
							}
						}
					}
				}


				&:after {
					content:'';
					background::url(../images/arrow-right-ffffff.svg) left center no-repeat;
					//padding: 0 20px 0 10px;
					margin: 0 0 0 20px;
					vertical-align: middle;
					-webkit-transition: background-position .3s;
					-moz-transition: background-position .3s;
					-ms-transition: background-position .3s;
					transition: background-position .3s;
					display: inline-block;
					height: 22px;
					background-size: auto 100%;
					width: 40px;
					position: absolute;
					right: 0;
					@media #{$narrow} {
						top: 17px;
					}
				}
				&:hover:after {
					background-position: right center;
				}
			}
		}
	}
  .block {
		&.last {
  		background: none;
  		max-width: 900px;
  		padding-top: 80px;
  		h1 {
    		font-weight: 200;
    		line-height: 1.25;
    		font-size: 24px;
    		@media #{$tablet_up} {
      		font-size: 36px;
    		}
    		@media #{$narrow} {
      		font-size: 46px;
    		}
  		}
  		p {
    		font-size: 18px;
    		@media #{$tablet_up} {
    		font-size: 24px;
    		}
    		line-height: 1.25;
    		text-align: left;
    		text-transform: none;
    		& + p {
      		margin-top: 2em;
    		}
  		}
  		a {
    		font-size: 18px;
    		color: $link;
    		font-weight: bold;
    		letter-spacing: .06em;
    		min-height: 0;
    		&:after {
      		display: none;
    		}
  		}
		}
	}
}



body.front .region-content-bottom {
	padding-bottom: 80px;
}

.views__focus_areas-block {
	padding-top: 2em;
	.view-content a {
		margin-bottom: 4px;
		background: #fff;
		width: 100%;
		border-left: 10px solid;
		display: block;//table;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top:0;
			bottom: 0;
			right: 0;
			width: 100%;
			background: #fff;
			-webkit-transition: width .35s;
			-moz-transition: width .35s;
			-ms-transition: width .35s;
			transition: width .35s;
			z-index: 0;
		}

		&:hover {
			color: $dark_grey;
			&:before {
				width: 0;
			}
		}
		div {
			//background: #fff;
			background: rgba(255,255,255,.85);
			-webkit-transition: background .35s;
			-moz-transition: background .35s;
			-ms-transition: background .35s;
			transition: background .35s;
			min-height: 120px;
			position: relative;
			display: table;//table-row;
			width: 100%;
			z-index: 1;
			img {
				opacity: 1;
				html.touchevents & {
					opacity: 1;
				}
				-webkit-transition: opacity .35s;
				transition: opacity .35s;
				//padding-right: 20px;
				@media #{$mobile} {
					padding-right: 10px;
				}
			}

			@media #{$mobile} {
				display: block;
				@include clearfix();
			}
			svg {
				position: relative;
				-webkit-transition: all .25s ease-in-out;
				-moz-transition: all .25s ease-in-out;
				-ms-transition: all .25s ease-in-out;
				transition: all .25s ease-in-out;
				//opacity: .4;
			}
			img,
			svg {
				vertical-align: middle;
			}


		}
		&:hover div {
			img {
				opacity: 1;
			}
			svg {
				-webkit-transform: translateX(20px);
				-moz-transform: translateX(20px);
				-ms-transform: translateX(20px);
				transform: translateX(20px);
				line,
				polyline {
					stroke: $light_grey !important;
				}
			}
		}
		span {
			display: table-cell;//inline-block;
			padding: 20px;
			vertical-align: middle;
			width: 75%;
			height: 120px;
			line-height: 34px;
			@media #{$mobile} {
				line-height: 24px;
			}
			&.icon {
				width:58px;
				padding-right: 0;
				@media #{$mobile} {
					padding-left: 10px;
					width: 38px;
					img {
						padding-right: 0;
					}
				}
			}
			&.count{
				@media #{$mobile} {
					padding: 3px 10px;
				}
			}
			&.link {
				@media #{$mobile} {
					padding: 3px 15px 8px;
				}
			}
			&.name {
				//width: 75%;
				font-size: 150%;
				//line-height: 1.3;
				padding: 20px 60px 20px 20px;
				font-weight: 200;
				letter-spacing: .06em;
				@media #{$mobile} {
					padding: 15px 20px 15px 10px;
					font-size: 1.1em;
				}
			}
			&.link {
				//text-align: right;
				width: 86px;
				min-width: 86px;
			}
			&.name + .link {
				width: 20%;
			}
			&.count {
				text-align: center;
				text-transform: uppercase;
				font-size: 80%;
				width: 120px;
				line-height: 20px;
				b {
					padding-right:1em;
					@media #{$tablet_up} {
						padding-right: 0;
						font-size: 2em;
						display: block;
					}
				}
			}
		}
		@media #{$mobile} {
			display: block;
			span {
				display: inline-block;
				//width: 100%;


				height: auto;
				&.count,
				&.link {
					float: left;
					width: 50%;
				}
				&.link {
					float: right;
					text-align: right;
				}
			}
		}
	}
}


.iframe {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.75%;
	iframe,
	.iframe-mask {
  	display: block;
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.iframe-mask {
  	cursor: pointer;
	}
}

.iframe-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.9);
  z-index: 1000;
  opacity: 0;
  -webkit-transition: opacity 3.5s;
  transition: opacity 3.5s;
  &.active {
    opacity: 1;
  -webkit-transition: opacity 3.5s;
  transition: opacity 3.5s;
  }
  .close {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    display: block;
    height: 36px;
    width: 36px;
    //background: rgba(0,0,255,.5);
    &:before {
      content:'';
      display: block;
      width: 40px;
      border-top:1px solid $grey;
      position: absolute;
      transform: rotate(45deg);
      top: 18px;
    }
    &:after {
      content:'';
      display: block;
      width: 40px;
      border-top:1px solid $grey;
      position: absolute;
      transform: rotate(-45deg);
      top: 18px;
    }
    &:hover:before,
    &:hover:after {
      border-color: $navy;
    }
  }
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }
}
