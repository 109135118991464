.view-partnerships {
	&.view-display-id-page,
	&.view-display-id-page_1,
	&.view-display-id-page_2 {
		.view-header {
			@media #{$tablet_up} {
			@include maxwidth(800px,40px,20px);
				margin-bottom: 80px;
			}
		}
		.attachment .view-header {
			margin-bottom: 0;
			padding-left: 0;
			padding-right: 0;
		}
		body.page-partnerships & {
		@media #{$tablet_up} {
			.view-filters-content {
				display: table;
				width: 100%;
			}
			.view-filters,
			.view-content {
				display: table-cell;
				vertical-align: top;
			}
			.view-filters {
				width: 30%;
				padding-right: 2em;
			}
			.view-content {
				width: 70%;
			}
		}
		}
		.views-exposed-widgets {
			position: relative;
		}
		.views-exposed-widget {
			float: none;
			clear: both;
			display: block;
			padding-right: 0;
			& + .views-exposed-widget {
				margin-top: 2em;
			}
			&.views-submit-button input {
				position: absolute;
				top: 0;
				right: 0;
				margin: 0;
				background-size: 18px;
			}
			&.views-widget-filter-title {
				padding: 0;
				label {
					display: none;
				}
				input {
					width: 100%;
				}

				::-webkit-input-placeholder {
				   color: $body;
				}

				:-moz-placeholder { /* Firefox 18- */
				   color: $body;
				}

				::-moz-placeholder {  /* Firefox 19+ */
				   color: $body;
				}

				:-ms-input-placeholder {
				   color: $body;
				}
			}
			&.bef-select-as-links {
				dt {
					font-weight: $normal;
					text-transform: uppercase;
					border-bottom: 1px solid $lightest_grey;
					padding: .5em 0;
					&:after {
						display: block;
						height: 18px;
						width: 18px;
						content: url(../images/angle-circle.svg);
						float: right;
						-webkit-transform: rotate(180deg);
						-moz-transform: rotate(180deg);
						-ms-transform: rotate(180deg);
						transform: rotate(180deg);
						-webkit-transition: -webkit-transform .25s;
						-moz-transition: -moz-transform .25s;
						-ms-transition: -ms-transform .25s;
						transition: transform .25s;
					}
					&.active:after {
						-webkit-transform: rotate(0deg);
						-moz-transform: rotate(0deg);
						-ms-transform: rotate(0deg);
						transform: rotate(0deg);
					}

				}
				.bef-select-as-links  {
					font-size: 80%;
					padding: 1em 0 1em 1em;
					a:before {
						content:'• ';
						color: transparent;
					}
					a:hover:before,
					a.active:before {
						color: $lime_green;
					}
				}
			}
		}
		.leaflet-container a {
			color: $body;
			&:hover {
				color: $lime_green;
			}
		}

		.leaflet-marker-pane img {
			opacity: .8 !important;
			&.active {
				opacity: 1 !important;
			}
		}
		.marker-cluster-small {
			background: rgba(139, 195, 75, .5) !important;
			div {
				background: rgba(139, 195, 75, .7) !important;
				color: #fff !important;
				font-weight: $black;
				font-size: 14px;
			}
		}
	}

	dd {
		img {
			max-width: 200px;
			max-height: 90px;
		}
	}
}


.page-partners .view-filters {
	//padding-bottom: 30px;
	margin-top: 60px;
	&:after {
		content:'';
		display: block;
		clear: both;
	}
	.views-exposed-widgets {
		margin-bottom: 0;
	}
	.chosen-container {
		margin: 0;
	}
}

.page-partners .region-content-bottom {
	background: #fff;
}
@media #{$mobile} {
	.page-partners .view-display-id-page .view-content {
		display: none;
	}
}

.views-exposed-form .views-exposed-widget {
	float: none;
	padding: 0;
	.page-partners & {
		position: relative;
		& + .views-exposed-widget {
			margin-top: 1em;
		}
		&.form-type-textfield {
			input {
				width: 100%;
			}
		}
		@media #{$tablet_up} {
			float: left;
			clear: none;
			width: 48.75%;
			margin-bottom: 1em;
			& + .views-exposed-widget {
				margin-top: 0;
			}
			&:nth-child(even) {
				margin-left: 2.5%;
			}
			.fieldset-wrapper {
				position: absolute;
				z-index: 200;
				background: #fff;
			}
		}
		@media #{$narrow} {
			width: 23%;
			margin-bottom: 0;
			& + .views-exposed-widget {
				margin-left: 2.65%;
			}
		}
		.views-submit-button {
			position: absolute;
			top: 0;
			right: 0;
			width: 40px;
			input {
				margin: 0px
			}
		}
	}
	fieldset {
		padding: 0;
		margin: 0;
		legend {
			text-transform: uppercase;
			margin: 0;
			padding: 0 0 .25em 8px;
			border-bottom: 1px solid $lightest_grey;
			width: 100%;
			.fieldset-legend {
				background: none;
				padding-left: 0;
				width: 100%;
				a {
					color: $body;
				}
			}
			a:after {
				display: block;
				height: 18px;
				width: 18px;
				content: url(../images/angle-circle.svg);
				float: right;
				margin-right: 10px;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-ms-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: -webkit-transform .25s;
				-moz-transition: -moz-transform .25s;
				-ms-transition: -ms-transform .25s;
				transition: transform .25s;
			}
		}
		&.collapsed legend a:after {
			-webkit-transform: rotate(180deg);
			-moz-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			transform: rotate(180deg);
		}
		&.collapsed .fieldset-legend {
			background: none;
		}
		&.bef-select-as-checkboxes-fieldset .fieldset-wrapper,
		.form-checkboxes {
			.region-content & {
				label {
				font-size: 80%;
				}
			}
			padding: .5em 9px;
			input {
				margin-right: 8px;
			}
			label {
				color: $body;
			}
			input:checked + label {
				color: $lime_green;
			}
			.form-type-radio,
			.form-type-bef-checkbox {
				padding-left: 1.5em;
				text-indent: -1.5em;
				line-height: 1.5;
				& + .form-type-bef-checkbox,
				& + .form-type-radio {
					margin-top: .25em;//.75em;
				}
			}
			.form-type-radio {
				text-indent: -1.8em;
			}
		}
	}
}


img.leaflet-marker-icon.synced-content-hover {
  height: 42px !important;
  width: 32px !important;
  margin-top: -48px !important;
  margin-left: -16px !important;
}

/* Special markers 42x32, increase h and w by 50% each */
.leaflet-tagged-marker.synced-content-hover > img {
  height: 42px !important;
  width: 32px !important;
  margin-top: -48px !important;
  margin-left: -16px !important;
}
.views-row.synced-marker-hover {
	background: none;
	a {
	color: $lime_green;
	}
}


.leaflet-container {
	font: inherit;
	@media #{$mobile} {
		height: 300px !important;
	}
}
.custom__marker .box,
.leaflet-popup-content-wrapper {
	box-shadow: none;
	border: 1px solid $lime_green;
	min-width: 140px;
	border-radius: 0;
	padding: 0;
	.box__content,
	.leaflet-popup-content {
		margin-left: 0;
		margin-right: 0;
		margin: 0;
		font-size: .8em;
		a h4 {
			padding-left: 20px;
			padding-right: 20px;
			font-weight: $black;
			text-transform: uppercase;
			text-align: center;
			margin: 20px 0 0;
			font-size: 100%;
		}
		a p {
			margin: 0;
			padding: 3px 20px;
		}
		a:hover p {
			color: $body;
		}
		a p.download {
			background: $background url(../images/view-project.svg) right 20px center no-repeat;
			background-size: 16px;
			padding: 6px 20px;
			color: $light_grey;
		}
	}
}
.leaflet-popup-tip-container {
	top: -1px;
	.leaflet-popup-tip {
		box-shadow: none;
		border: 1px solid $lime_green;
		background: $background;
	}
}



/*! ====== CORE PARTNERS */
.view-partnerships.view-display-id-page_2 {
	.attachment-before {
		max-width: 800px;
		margin: 0 auto 60px;
		ul {
			margin: 1em 0 2em 0;
		}
	}
	.view-content {
		clear: both;
	}
}

/*! ====== CORE PARTNERS LOGOS */
.view-partnerships.view-display-id-block_1  {
	//.attachment-after {
		.td {
			background: #fff;
			width: 50%;
			float: left;
			&.col-1,
			&.col-3 {
				clear: left;
			}
			&.col-2,
			&.col-4 {
				clear: right;
			}
			@media #{$tablet_up} {
				width: 25%;
				float: none;
			}
			padding: 30px;
			text-align: center;
			vertical-align: middle;
			@media #{$tablet_up} {
				border:1px solid $background;
			}
			img {
				max-height: 70px;
				width: auto;
			}

		}
		.views-row {
			display: inline-block;
			background: $background;
			width: 50%;
			@media #{$tablet_up} {
				width: 25%;
			}
			padding: 30px;
			text-align: center;
			//float: left;
			position: relative;
			img {
				max-height: 70px;
				width: auto;
			}
		}
//	}
	&:after {
		content:'';
		display: block;
		clear: both;
	}

}

.partner__field_image,
.partner__field_team_member,
.partner__field_team_members,
.Xfield_team_members {
	text-align: center;
	.field-label {
		text-align: center;
		font-size: 1.15em;
		font-weight: $normal;
	}
	.field-item {
		display: inline-block;
		margin: 0 1em;
	}
}

//.field_team_members,
.partner__field_team_member,
.partner__field_team_members {
	position: relative;
	margin-bottom: 4em;
	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 25%;
		width: 50%;
		border-bottom: 1px solid $lightest_grey;
	}
}

.field-name-field-download-mou {
	margin: 60px 0;
}

// logo
.node-partner .partner__field_image {
	margin-top: 100px;
	img {
		display: inline-block;
		margin: 0 auto;
	}
}

.node-partner .partner__title {
	text-align: center;
}



/*! ====== EXPERIMENTAL */
.apec_helpers__core_funders_map,
.view-partnerships.view-display-id-page_2 {
	position: relative;
	background: url(/sites/default/files/CorePartners-Map.png) center top no-repeat;
	background-size: 100% auto;
	padding-bottom: 62.5%;
	height: 0;
	.equator {
		position: absolute; top:56.266%; width:100%; height:1px; background:rgba(255,255,255,.5);
	}
	.dateline {
		position: absolute; left: 41.416%; top:0; height:100%; width:1px; background:rgba(255,255,255,.5);
	}
	a.icon {
		position: absolute;
		display: block;
		span {
			height: 21px;
			width: 16px;
			position: absolute;
			bottom: 0;
			left: -8px;
			background: url(../amarkers/pink.png) center center no-repeat;
			background-size: 100%;
		}
		&.host span {
			background-image: url(../amarkers/blue.png);
		}
	}
}

body.page-partners main .view-content,
.view-partnerships .attachment {
	background: #cad2d3;
	margin: 0 -40px;
	section {
		position: relative;
		max-width: 1800px;
		margin: 0 auto;
	}
	img {
		display: block;
		max-width: 100%;
	}
}


a.td.fa-0 {
	background: $background;
	&:hover {
		background: rgba(4,44,76,.15);
	}
}
a.td.fa-0 span {
	background: #F5F5F6;
}
a.td.fa-0:hover span {
	background: $navy;//$link;//$mid_grey;//$light_grey;
}
body.page-partners .view {
	.table {
		margin: 30px 0;
		@media #{$tablet_up} {
			border-spacing: 30px;
			margin: 0 -30px 40px;
			width: calc(100% + 60px);
		}
		@media #{$narrow} {
			border-spacing: 60px;
			margin: 0 -60px 40px;
			width: calc(100% + 120px);
		}
	}

	a.td {
		margin: 0 0 20px 0;
		padding: 20px 20px 80px;
		@media #{$tablet_up} {
			display: table-cell;
			width: 33.3333%;
			padding: 20px 20px 80px;
		}
		position: relative;
		-webkit-transition: background .25s ease-in-out;
		-moz-transition: background .25s ease-in-out;
		-ms-transition: background .25s ease-in-out;
		transition: background .25s ease-in-out;
		&:hover {
			h2 {
				color: $navy;//$link;
			}
			span {
				color: #fff;
				svg path,
				svg polygon,
				svg rect {
					fill: #fff;
				}
			}
		}
		h2 {
			font-weight: 200;
			margin: 0;

		}
		h4 {
			font-size: 120%;
		}
		span {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 15px 20px;
			font-size: .8rem;
			text-transform: uppercase;
			color: $light_grey;
			-webkit-transition: background .25s ease-in-out;
			-moz-transition: background .25s ease-in-out;
			-ms-transition: background .25s ease-in-out;
			transition: background .25s ease-in-out;
			svg {
				float: right;
			}
		}

	}
}


div.custom__marker {
	position: absolute;
	height: 21px;
	width: 16px;
	background: url(../amarkers/green.png) center center no-repeat;
	background-size: 100%;
	margin-left: -15px;
	margin-top:-20px;
	cursor: pointer;
	-webkit-transition: all .15s ease-in-out;
	-ms-transition: all .15s ease-in-out;
	transition: all .15s ease-in-out;
	z-index: 1;
	&:hover {
		margin-top: -26px;
	}
	&.active:hover {
		margin-top: -20px;
	}
/*
	span.marker {
		height: 21px;
		width: 16px;
		position: absolute;
		bottom: 0;
		left: -8px;
	}
*/
	.box {
		position: absolute;
		//display: none;
		height: 0;
		overflow: hidden;
		opacity: 0;
		//padding: 10px;
		background: #fff;
		bottom: 10px;
		width: 300px;
		left: -142px;
		-webkit-transition: all .15s ease-in-out;
		-moz-transition: all .15s ease-in-out;
		-ms-transition: all .15s ease-in-out;
		transition: all .15s ease-in-out;
		.close {
			position: absolute;
			top: -2px;
			right: 8px;
			font-family: Verdana,sans-serif;
			font-size: 11px;
			&:hover {
				color: $lime_green;
			}
		}
		.box__content {
			a {
				color: $body;
				&:hover,
				&:hover p.more {
					color: $lime_green;
				}
				h4 {
					//margin: 0;
				}
				p {
					//margin: 0;
					font-weight: 400;
					line-height: 1.3;
					&.more {
						background: $background;
						text-transform: uppercase;
						color: $light_grey;
						padding-top: 10px;
						padding-bottom: 8px;
						line-height: 17px;
						svg {
							float: right;
						}
					}
				}
			}
		}
		&:after {
			content:'';
			height: 10px;
			width: 10px;
			border-bottom:1px solid $lime_green;
			border-right:1px solid $lime_green;
			background: $background;
			display: block;
			position: absolute;
			left: 50%;
			bottom: -6px;
			margin-left: -6px;
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
	&.active {
		z-index: 10;
		.box {
			height: auto;
			display: block;
			overflow: visible;
			opacity: 1;
			bottom: 30px;
		}
	}

}
